@media only screen and (max-width: 768px) {
    $grid-gutter-width: 8px;
}

*, *::before, *::after {
    box-sizing: inherit;
}

html {
    /* this makes sure the padding and the border is included in the box sizing */
    box-sizing: border-box;
    overflow-x: hidden;
}

html body {
    // background-image:    url('../images/gradientBg.jpeg');
    background-color: #F3F5FB;
    background-position: top center;
    background-repeat:   no-repeat;
    background-size:     auto;
    margin:              0;
    overflow-x:          inherit;
    padding:             0;
}

.gutter {
    padding: 8px;
    width:   100%;
}

/* Typography */

.fw-500 {
    font-weight: 500;
}

/* Start - Inputs */


input[type='text'], input[type='password'], select, input[type='date'], input[type='number'], input[type='email'], input[type='tel'], input[type='time'], textarea.form-control, select.form-select {
    background-color:   rgba(239, 240, 247, 0.05);
    border:             1px solid rgba(255, 255, 255, 0.5);
    border-radius:      10px;
    -webkit-box-shadow: unset;
    box-sizing:         border-box;
    color:              white;
    font-size:          14px;
    font-style:         normal;
    font-weight:        400;
    letter-spacing:     0.75px;
    line-height:        28px;
    padding:            1rem 0.75rem;
    transition:         border 200ms;

}

select.form-select {
    padding-right: 36px;
}

/* Hover */
input[type='text']:hover, input[type='password']:hover, select:hover, input[type='date']:hover, input[type='number']:hover, input[type='email']:hover, input[type='tel']:hover, input[type='time']:hover, textarea:hover {
    border-color: white;
}

/* Focus */

input[type='text']:focus, input[type='password']:focus, select.form-control:focus, input[type='date']:focus, input[type='number']:focus, input[type='email']:focus, input[type='tel']:focus, input[type='time']:focus, textarea.form-control:focus {
    background-color: rgba(239, 240, 247, 0.05);
    border-color:     white;
}

input:not(:placeholder-shown), textarea:not(:placeholder-shown) {
    color: white !important;
}

input[type='checkbox'] {
    width: 20px !important;
}

input[type='checkbox'] ~ label {
    margin-left: 12px;
}

input::placeholder {
    color: var(--bs-secondary);
}

input:disabled, textarea:disabled {
    background-color: unset;
    border-color:     transparent;
    color:            #C5CDD9;
}

input.is-invalid, select.is-invalid {
    border-color: var(--bs-danger) !important;
    box-shadow:   0px 2px 4px rgba(156, 25, 25, 0.08), 0px 4px 12px rgba(156, 25, 25, 0.12) !important;
    color:        var(--bs-danger) !important;
}

input.is-valid, select.is-valid {
    border-color: var(--bs-success) !important;
    /*box-shadow: 0px 2px 4px rgba(156, 25, 25, 0.08), 0px 4px 12px rgba(156, 25, 25, 0.12) !important;*/
    color:        var(--bs-success) !important;
}

input.is-valid::placeholder, select.is-valid::placeholder, textarea.is-valid::placeholder {
    color: var(--bs-success) !important;
}

input.is-invalid::placeholder, select.is-invalid::placeholder, textarea.is-invalid::placeholder {
    color: var(--bs-danger) !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
    transition:              background-color 5000s ease-in-out 0s, border 200ms !important;
}


input.error, textarea.error, select.error {
    border-color: #ed4b4b;
}

input.error::placeholder, textarea.error::placeholder {
    color: #ed4b4b;
}

select.error {
    color: #ed4b4b;
}

.input-sm {
    border-radius: 18px;
}

select.form-select option {
    color: black;
}

.input-group {
    position: relative;
}

.input-group-text {
    background-color: transparent;
    border:           0;
    border-color:     #e9ecef;
    color:            var(--bs-secondary);
    position:         absolute;
    z-index:          10;
}

.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
    border-bottom-left-radius: 8px;
    border-top-left-radius:    8px;
    padding-left:              32px;
}

.invalid-feedback {
    color: #f25a69;
}

.valid-feedback {
    color: #3dc083;
}

.valid-feedback, .invalid-feedback {
    letter-spacing: 1px;
}

.form-floating > textarea.form-control:focus, .form-floating > textarea.form-control:not(:placeholder-shown) {
    padding-top: 2.25rem;
}

input[type="date"].is-invalid::-webkit-inner-spin-button,
input[type="date"].is-invalid::-webkit-calendar-picker-indicator {
    opacity: 0;
}

input[type="time"].is-invalid::-webkit-inner-spin-button,
input[type="time"].is-invalid::-webkit-calendar-picker-indicator {
    opacity: 0;
}


/* End - Inputs */


/* Buttons */

.btn-primary {
    color:  white !important;
    height: fit-content;

    .disabled, &:disabled {
        background:   var(--bs-primary);
        border-color: var(--bs-primary);
    }

}

.btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color:   #ee5b8c;
    border-color:       #ee5b8c;
    -webkit-box-shadow: unset !important;
}

.btn-transparent {
    align-items:     center;
    border:          2px solid rgba(255, 255, 255, 0.2);
    display:         flex;
    height:          44px;
    justify-content: center;
    transition:      all 200ms;
    vertical-align:  middle;
    width:           44px;

    svg {
        path {
            transition: all 200ms;
        }
    }

    &:hover {
        background: white;
    }

    &:hover svg path {
        fill: black;
    }
}

.accordion-body {
    font-size: 0.9rem;
}

small {
    color:       rgba(255, 255, 255, 0.6);
    font-size:   0.8rem;
    font-weight: 600;
}