.listings-table {
    background-color: #181a26;
    border: 2px solid var(--bs-primary);
    border-radius: 24px;
    display: block;
    overflow: auto;
    padding: 2em;
}

.top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1em;
}

li.page-item {
    a.page-link {
        border: 1px solid var(--bs-primary);
        background-color: #181a26;
        color: #ffffff;
    }

    &.disabled {
        .page-link {
            background-color: #181a26;
            border: 1px solid var(--bs-primary);
        }
    }
}

.listing-preview {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-size: contain;
}

.listing-item {
    height: 68px;
    td {
        vertical-align: middle;

        .listing-name {
            display: flex;
            flex-direction: column;
            align-items: start;
            justify-content: center;
        }

        .status, .source {
            background-color: var(--bs-gray);
            color: #ffffff;
            line-height: 1;
            border-radius: 4px;
            padding: 2px 4px;
            font-size: 12px;
            margin-right: .5em;

            &.status-active {
                background-color: var(--bs-green);
            }

            &.status-inactive {
                background-color: var(--bs-red);
            }

            &.status-draft {
                background-color: var(--bs-yellow);
            }

            &.source-guesty {
                background-color: var(--bs-blue);
            }

            &.source-internal {
                background-color: var(--bs-pink);
            }
        }
    }
}

.listings-source.btn-group, .listings-status.btn-group {
    button.btn.btn-outline-primary {
        color: #ffffff;
    }
}
