@import '../../App.scss';

.container {
  .loader__inner {
    background-color: #000;
  }
}

.parent_container {
  align-items: flex-end;
}

.makLogo {
  margin-bottom: 40px;
}

.breakdownAcardion {
  .accordion-item {
    .price-accordion-title {
      button {
        color: #1B2540;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        padding: 0px 0px 20px 0px;
      }
    }
  }
}

.pricing_table_wrapper {
  table {
    color: #676767 !important;
    font-family: 'Montserrat' !important;
    font-size: 16px !important;

    tbody {
      tr td:last-child {
        color: black !important;
      }
    }
  }

  td.darker {
    color: #676767 !important;
    border-bottom: 1px solid #edebe7 !important;
    font-family: Montserrat !important;
    font-size: 16px !important;
    font-weight: 400 !important;
  }
  tr {
    display: table;
    table-layout: fixed;
    width: 100%;
  }

  tbody {
    display: block;
    max-height: 350px;
    overflow-y: scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      box-shadow: inset 0 0 5px grey;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $base-blue;
      border-radius: 10px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $base-blue;
    }
  }
}

.listing-page {
  .new-listing-hotel-info {
    margin-top: 34px;

    @media only screen and (max-width: 768px) {
      margin-top: 16px;
    }
  }

  h1 {
    color: $base-blue;
    font-family: 'Cormorant Garamond';
    font-size: 80px;
    font-style: normal;
    font-weight: 500;
    line-height: 100%;
    padding-bottom: 24px;
    margin-top: 20px;
  }

  h2 {
    color: $base-blue;
    font-family: Montserrat;
    font-size: 14px;
    font-weight: 500;
    line-height: 100%;
    padding: 5px 0px;
  }
  .listing-title {
    color: $base-blue;
    font-family: Montserrat;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    padding-bottom: 16px;
    margin: 15px 0;
  }

  .location-title {
    h5 {
      color: $base-blue;
      font-size: 16px;
      font-family: Montserrat;
      line-height: 24px;
      margin-bottom: 0px !important;
      padding-top: 3px;
      font-weight: 400;

      small {
        font-size: 16px;
        font-weight: 400;
        line-height: 19.2px;
        margin-left: 18px !important;
        color: #a5adc5;
      }
    }
  }
  .icon-boxes {
    font-family: 'Montserrat';

    @media only screen and (min-width: 768px) {
      padding-right: 24px;
    }
  
    .short-info {
      .info-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        background-color: white;
        margin: 1px;
        color: $base-blue;
        padding: 32px 24px;
        gap: 12px;

        p {
          font-weight: 500;
          font-size: 16px;
          line-height: 25.6px;
          font-family: 'Montserrat';

          @media only screen and (max-width: 768px) {
            font-size: 14px;
            line-height: 22.4px;
          }
        }

        svg,
        span {
          opacity: 0.6;
        }
      }
    }
  }

  .image-gallery {
    overflow: hidden;

    .image-gallery-slide-wrapper {
      width: 57%;

      img {
        border-radius: 0px;
      }
    }

    .image-gallery-thumbnails {
      width: max-content;

      nav {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        button {
          margin-bottom: 1% !important;
          margin-right: 1% !important;

          &.image-gallery-thumbnail.active,
          &.image-gallery-thumbnail:focus {
            border: 2px solid $base-blue;
            overflow: hidden;
          }

          @media screen and (min-width: 798px) {
            flex-basis: 344px;
          }
        }
      }
    }

    .image-gallery-bullets {
      .image-gallery-bullets-container {
        .image-gallery-bullet {
          height: 3px;
          width: 24px;
          background-color: #FFFFFF;
          opacity: 0.5;
          padding: 0;
          border-radius: initial;

          &.active {
            opacity: 1;
          }
        }
      }
    }
  }

  .amenities {
    padding-right: 0.5rem;
    h5 {
      margin-bottom: 32px;
      padding-bottom: 0;
      font-family: 'Cormorant Garamond';
      font-size: 36px;
      font-weight: 400;
      color: $base-blue;
    }
    .amenity {
      display: flex;
      gap: 20px;
      margin-left: 0px;
    }

    .amenity-item {
      width: 31%;
      color: $base-blue;
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 400;
      line-height: 25.6px;
      text-align: left;
      border-bottom: 1px solid #9fb0ba33;
      padding: 0 12px 12px 0;
      color: #1b2540;
      display: flex;
      align-items: center;
      height: fit-content;
      gap: 8px;

      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 21px
      }
    }
    .amenity-item-icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
      opacity: 0.4;
      color: #1B2540 !important;
    }
  }

  .price-breakdown-container {
    position: sticky !important;
    top: 20px !important;
    z-index: 100 !important;
  }

  .price-breakdown {
    background-color: white;
    border: 1px solid #fff;
    height: fit-content;
    padding: 28px 24px;

    .book-now-btn {
      align-items: center;
      background: $base-blue;
      display: flex;
      justify-content: center;
      border: 0;
      padding: 8px 13px;
      width: 100%;
      color: white;
    }

    .disabled-link {
      background: #8b8686;
      cursor: not-allowed;
      pointer-events: none;
      border: 1px solid #a7a7a7;
    }
  }

  .disabled-link {
    background: #8b8686;
    cursor: not-allowed;
    pointer-events: none;
    border: 1px solid #a7a7a7;
  }

  .tour3d-container {
    margin-top: 64px;
    width: 100%;

    .tour3d-title {
      color: #1B2540;
      font-family: "Cormorant Garamond";
      font-size: 36px;
      font-style: normal;
      font-weight: 400;
      line-height: 43.2px;
      letter-spacing: -0.72px;
      margin-bottom: 32px;

      @media screen and (max-width: 768px) {
        font-family: "Cormorant Garamond";
        font-size: 28px;
        line-height: 33.6px;
        letter-spacing: -0.56px;
        margin-bottom: 24px;
      }
    }

    .tour3d-preview {
      width: 100%;
      height: 360px;
      border-radius: 10px;
      position: relative;

      img {
        width: 100%;
        height: 100%;
        border-radius: 10px;
      }
    } 

    iframe {
      width: 100%;
      height: 360px;
      border-radius: 10px;
    }

    .tour3d-drag-button {
      position: absolute;
      width: 120px;
      height: 120px;
      background: rgba(0, 0, 0, 0.20);
      backdrop-filter: blur(9.5px);
      border-radius: 100%;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: #FFF;
      text-align: center;
      font-family: Montserrat;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 20.3px;
      letter-spacing: 1.12px;
      text-transform: uppercase;
      cursor: pointer;

      @media screen and (max-width: 768px) {
        .tour3d-drag-button-icon {
          display: none;
        }
      }
    }
  }

  .map-preview {
    margin-bottom: 32px;
    margin-top: 64px;
    border-radius: 10px;

    .map-container {
      border-radius: 10px;
      height: 360px;
      overflow: hidden;
    }
  }

  .accordion-section {
    margin: 10px 0px;
    button.accordion-button {
      color: $base-blue;
      padding: 20px 0;
      font-weight: 500;
      font-size: 14px;
      font-family: 'Montserrat';
      line-height: 20.3px;
      letter-spacing: 1.12px;
      text-transform: uppercase;
    }
    button.accordion-button:focus {
      // border-color: transparent;
      outline: none;
    }

    .accordion-button:not(.collapsed) {
      background-color: none !important;
    }

    .accordion-body {
      color: #676767 !important;
      font-size: 16px;
      font-family: 'Montserrat';

      @media screen and (max-width: 768px) {
        font-size: 14px;
        line-height: 21px;
      }
    }

    .accordion {
      --bs-accordion-btn-active-icon: url('../../assets//icons/blue.svg') !important;
      --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem $base !important;
    }
  }

  @media screen and (max-width: 1440px) {
    padding-left: 1em;
    padding-right: 1em;
  }

  @media screen and (max-width: 1399px) {
    .image-gallery-slide-wrapper.image-gallery-thumbnails-bottom {
      width: 100%;
    }

    .image-gallery-thumbnails-wrapper.image-gallery-thumbnails-bottom {
      width: 100%;
      padding: 10px;
    }

    .image-gallery-thumbnails {
      width: 100% !important;
      .image-gallery-thumbnails-container {
        width: 100% !important;
        display: flex !important;
        flex-wrap: nowrap !important;
        overflow-x: auto;
        padding-bottom: 10px;
        scrollbar-width: none;
        transition: none !important;
        transform: none !important;
      }

      .image-gallery-thumbnail {
        flex: 0 0 120px;
        width: 120px;
        object-fit: cover;
        margin-right: 2px;
        transition: none;
        height: 100px;
        &.active {
          overflow: visible !important;
        }
        img {
          height: 100px;
          width: 100%;
        }
      }

      .image-gallery-thumbnails-container::-webkit-scrollbar {
        display: none;
      }
    }

    .image-gallery {
      background-color: #F3F5FB;
    }

    .fullscreen.image-gallery-content {
      background-color: #F3F5FB;
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      height: 100%;
      @media screen and (max-width: 799px) {
        padding-top: 132px;
        position: relative;
      }

      .image-gallery-slide-wrapper.image-gallery-thumbnails-bottom {
        width: 100%;
        @media screen and (max-width: 799px) {
          position: static;
        }

        .image-gallery-image {
          @media screen and (max-width: 799px) {
            height: 500px;
            max-height: none;
          }

          max-height: 500px;
        }
        .image-gallery-bullets {
          bottom: -200px;

          @media screen and (max-width: 799px) {
            bottom: 200px;
          }
        }
        .image-gallery-play-button, .image-gallery-fullscreen-button {
          bottom: -200px;

          @media screen and (max-width: 799px) {
            bottom: 200px;
          }
        }
        .image-gallery-left-nav, .image-gallery-right-nav {
          @media screen and (max-width: 799px) {
            top: 370px;
          }
        }
        .custom-X-button {
          svg {
            @media screen and (min-width: 798px) {
              color: #1B2540;
            }
          }
        }
      }

      image-gallery-thumbnails {
        width: 100% !important;
      }
    }
  }

  @media screen and (max-width: 991px) {
    .price-breakdown {
      width: 100% !important;
      margin-left: 0;
    }

    .mobile-book-panel {
      display: flex !important;
    }

    .equal-size {
      width: 100% !important;
    }
  }

  @media screen and (max-width: 500px) {
    .resort {
      font-size: 45px !important;
    }

    .reflect {
      font-size: 0.4rem !important;
    }
  }

  .interesting-events {
    margin-top: 64px;

    @media screen and (max-width: 768px) {
      margin-top: 32px;
    }

    h5 {
      font-family: 'Cormorant Garamond';
      font-size: 36px;
      line-height: 43.2px;
      letter-spacing: -0.72px;

      @media screen and (max-width: 768px) {
        font-size: 28px;
        line-height: 33.6px;
        letter-spacing: -0.56px;
      }
    }
    
    .event-item-mb-20 {
      margin-bottom: 20px;
    }

    .event-item {
      width: 100%;
      background-color: white;
      padding: 32px;

      @media screen and (max-width: 991px) {
        margin-bottom: 32px;
      }

      @media screen and (max-width: 991px) and (min-width: 768px) {
        max-height: 270px;
      }

      &:hover {
        .view-more {
          background-color: $base-blue;
          color: white !important;
        }
      }
    }
    .event {
      display: flex;
      gap: 13px;
      height: 100%;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
      }

      // flex-direction: row;
      // padding: 0 15px;

      &:hover {
        border: 1px solid rgba(255, 255, 255, 0.2);
      }

      .info {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // padding-right: 32px;
        width: 100%;
        gap: 12px;

        @media screen and (max-width: 768px) {
          order: 2;
        }

        .event {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 8px;
        }
        .name {
          color: $base-blue;
          font-family: 'Cormorant Garamond';
          font-size: 24px;
          font-weight: 500;
          line-height: 23px;
          letter-spacing: -0.02em;
          text-align: left;
          // margin-bottom: 20px;
        }

        .time {
          color: #676767;
          font-family: Montserrat;
          font-size: 14px;
          font-weight: 400;
          line-height: 22.4px;
          text-align: left;
        }

        .view-more {
          width: 125px;
          border: 1px solid $base-blue;
          border-radius: 0;
          color: $base-blue;
          padding: 12px 24px;
          text-align: center;
          text-wrap: nowrap;
          font-family: 'Montserrat';
          font-size: 12px;
          font-weight: 500;
          line-height: 12px;
          letter-spacing: 0.24px;
          text-transform: uppercase;
        }
      }

      .img {
        text-align: center;
        width: 100%;

        @media screen and (max-width: 768px) {
          order: 1;
        }

        img {
          // max-height: 204px;
          width: 100%;
          object-fit: cover;
          height: 100%;

          @media screen and (max-width: 768px) {
            max-height: 230px;
          }
        }
      }
    }
  }

  .mobile-book-panel {
    background: #FFFFFF;
    bottom: 0px;
    display: flex;
    display: none;
    flex-direction: row;
    justify-content: space-between;
    left: 0px;
    margin-right: 0;
    padding: 16px;
    padding-bottom: 12px;
    position: fixed;
    right: 0px;
    width: 102%;
    z-index: 10;

    > div {
      width: 50%;
    }

    .price {
      color: #1B2540;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 24px;
      /* 100% */
    }

    span {
      color: #727475;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 28.8px;
      /* 180% */
    }

    .buttons {
      align-items: center;
      display: flex;
      justify-content: end;
      margin-right: 0;
      padding-right: 0;

      .mobile-cart-button {
        background: #1B2540;
        border-color: #1B2540;
      }
    }
  }

  .fixed {
    left: 58%;
    top: 80px;
    z-index: 100;
    position: fixed;
    width: 100% !important;
    max-width: 37%;
  }

  @media (max-width: 1800px) {
    .fixed {
      left: 58.5%;
      max-width: 38%;
    }
  }
  @media (max-width: 1700px) {
    .fixed {
      left: 59%;
      max-width: 39%;
    }
  }

  @media (max-width: 1650px) {
    .fixed {
      left: 59%;
      max-width: 40%;
    }
  }

  @media (max-width: 1500px) {
    .fixed {
      left: 59%;
      max-width: 40%;
    }
  }

  @media (max-width: 420px) {
    .lastPrice {
      font-size: 12px !important;
    }

    .info-item {
      padding: 10px !important;
    }
  }

  @media (max-width: 355px) {
    .resort {
      font-size: 35px !important;
    }
    .info-item {
      padding: 2px !important;
    }
  }

  .equal-size {
    display: flex;
    flex-wrap: wrap;
  }
}

.fullscreen-gallery {
  height: 100dvh;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
}

.image-gallery-thumbnail .image-gallery-thumbnail-image {
  height: 250px;
  object-fit: cover;
}

.image-gallery-thumbnail.active {
  border: none !important;
  opacity: 0.5;
}

.custom-X-button {
  background-color: transparent;
  border: none;
  left: 10px;
  position: fixed;
  top: 10px;
  transition: background-color 0.3s ease-in-out;
  z-index: 999;

  &:hover {
    transform: scale(1.1);
  }

  svg {
    color: white;

    &:hover {
      color: #337ab7;
    }
  }
}

.accordion-item {
  color: white !important;
  border-bottom: 1px solid #9fb0ba !important;
}

.accordion-button::after {
  background-image: url('../../assets//icons/blue.svg') !important;
}

.accordion-header .accordion-button:focus {
  box-shadow: none;
}

.image-gallery-thumbnails {
  width: 50vw !important;
}

.listing-page .image-gallery .image-gallery-thumbnails nav button {
  margin: 0 !important;
  border-width: 1px !important;
}
@media only screen and (min-width: 1400px) {
  .fullscreen {
    .image-gallery-slide-wrapper {
      width: 80% !important;
    }
  
    .image-gallery-thumbnails {
      width: 20vw !important;
    }
  }
}

.star-rating {
  display: flex;
  gap: 12px;
  color: $base-blue;
}
.breadcrumbs {
  width: 100%;
  padding-top: 3em;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  font-weight: 500;

  @media only screen and (max-width: 768px) {
    padding-top: 36px;
  }

  ol.breadcrumb {
    gap: 16px
  }

  .breadcrumb-item + .breadcrumb-item::before {
    content: '/';
    color: #9fb0ba;
    padding-right: 16px;
  }

  .breadcrumb-item {
    padding-left: 0 !important;
    a {
      color: $base-blue;
    }
  }

  .breadcrumb-item.active {
    color: #9fb0ba;
  }
}

.jso-booking-form-content {
  .jso-booking-form-title {
    background: unset !important;
    background-color: white !important;
    border: unset;
    border-radius: unset;
    color: $base-blue !important;
    border-bottom-color: $base-border-color !important;
    font-family: 'Cormorant Garamond' !important;
    font-size: 24px !important;
    font-weight: 500 !important;
    text-transform: none;
    svg.calendar-icon {
      path {
        fill: none;
      }
    }
  }
  .jso-booking-check-in-out,
  .jso-booking-form-guests {
    background: white;
    border: solid 1px $base-border-color !important;
    border-radius: unset;
  }
  .jso-booking-check-in-out-content,
  .jso-booking-form-guests {
    background: white !important;
    border-radius: unset;
    .jso-date-range-picker-text,
    .jso-quantity-selector-text {
      color: #676767;
      border-color: $base-border-color !important;
      border-left: unset;
      font-family: 'Montserrat';
      font-size: 18px;
    }
  }
  .jso-booking-form-search-btn {
    background-color: $base-blue !important;
    border-radius: unset;
  }
}

.breakdown-btns-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0;
  gap: 32px;
}

.breakdown-btn {
  background: none;
  border: 1px solid $base-blue;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 22px 24px;
  flex: 1;
  max-height: 47px;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.02);
  }

  span {
    margin-left: 10px;
    color: #1B2540;
    font-family: Montserrat;
    font-size: 12px;
    font-weight: 500;
    line-height: 12px;
    letter-spacing: 0.24px;
    text-align: center;
  }
}

@media only screen and (max-width: 390px){
  .amenities{
    padding: 0 !important;
    width: 100%;
  }
  .amenity{
    justify-content: space-between;
    width: 100%;
    align-items: center;
  }
  .amenity-item{
    display: flex;
    gap: 30px;
    width: 45% !important;
    padding-right: 0 !important;
    height: 100% !important;
  }
}

.fa-icon-sizes {
  width: 24px;
}

.notice-period {
  font-family: 'Montserrat';
}
