.listing-not-fount-warning {
    background-color: #FFFFFF;
    padding: 32px;
    display: flex;
    align-items: center;
    gap: 24px;

    @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 16px;
    }

    .icon-container {
        background-color: #F3F5FB;
        border-radius: 50%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .text-container {
        display: flex;
        flex-direction: column;
        gap: 12px;
        
        @media screen and (max-width: 768px) {
            text-align: center;
        }

        .main-text {
            font-size: 24px;
            line-height: 24px;
            font-family: 'Cormorant Garamond';
            font-weight: 500;
            color: #1B2540;
            margin-bottom: 0;
        }
        .secondary-text {
            font-family: 'Montserrat';
            font-size: 16px;
            line-height: 25.6px;
            color: #61676B;
            margin-bottom: 0;

            span.phone {
                color: #1B2540;
                text-decoration: underline;
                display: inline-block;
            }
        }
    }
}
