/**
 * Ocean Star - Hotels Search Form Styles
 */
.jso-booking-form{
    --jso-booking-form-width: 100%;

    --jso-booking-form-bg-color: #fff;
    --jso-booking-form-text-color: #111;

    --jso-booking-form-border-color: #ddd;
    --jso-booking-form-border-radius: 0.5rem;
    --jso-booking-form-shadow-color: #e0e0e0;
    --jso-booking-form-focus-shadow-color: rgb(144, 144, 144);

    --jso-booking-form-button-bg-color: #4e4e4e;
    --jso-booking-form-button-bg-focus-color: #333;
    --jso-booking-form-button-text-color: #fff;

    --jso-booking-form-error-color: #a30000;
    --jso-booking-form-overlay-bg-color: #7d7d7d;
}

.jso-booking-form{
    font-family: -apple-system, system-ui, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
    color: var(--jso-booking-form-text-color, #111);
    font-size: 1rem;

    width: var(--jso-booking-form-width, 520px);
    max-width: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;
}

.jso-booking-form h3 {
    margin-bottom: 1.8rem;
    font-size: 2rem;
    font-weight: 900;
    text-align: center;
}

.jso-booking-form-content{
    width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
}

.jso-booking-form-country,
.jso-booking-check-in-out,
.jso-booking-form-guests{
    margin: 0 0 1rem 0;
    border-radius: 0.5rem;
    border: 0;
    box-shadow: var(--jso-booking-form-shadow-color) 0 0 10px;
    position: relative;
    background: var(--jso-booking-form-bg-color, #fff);
    transition: 0.2s box-shadow;
}

.jso-booking-form-title{
    padding: .7rem 1rem;
    margin: 0;
    background: var(--jso-booking-form-bg-color, #fff);
    border-bottom: 1px solid var(--jso-booking-form-border-color, #efefef);
    border-radius: var(--jso-booking-form-border-radius, 0.5rem) var(--jso-booking-form-border-radius, 0.5rem) 0 0;

    text-transform: uppercase;
    font-size: .8rem;

    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.jso-booking-form-content h4 svg{
    margin-right: 0.5rem;
    transition: 0.3s all;
}

.jso-booking-form-content h4 svg:hover{
    transform: translateY(-0.2rem);
}

/**
 * countries autocomplete
 */
.jso-booking-form-country-content{
    display: flex;
    width: 100%;
    box-sizing: border-box;
}

.jso-booking-form-country-content input{
    width: 100%;
    height: 50px;
    border: 0;
    box-sizing: border-box;
    border-radius:  0 0 var(--jso-booking-form-border-radius, 0.5rem) var(--jso-booking-form-border-radius, 0.5rem);
    font-size: 1rem;
    padding: 0.5rem 1rem;
    background: var(--jso-booking-form-bg-color, #fff);
    color: var(--jso-booking-form-text-color, #111);
}

.jso-booking-form-country-content input:active,
.jso-booking-form-country-content input:focus{
    outline: none;
    box-shadow: none;
}

.jso-booking-form-country-content ::placeholder{
    color: var(--jso-booking-form-text-color, #111);
}

.jso-booking-form-country-content .jso-dropdown{
    border-radius:  var(--jso-booking-form-border-radius, 0.5rem);
    overflow: hidden;
    box-shadow: var(--jso-booking-form-shadow-color) 0 0 10px;
    font-size: 0.95rem;
}

.jso-booking-form .jso-autocomplete{
    margin-top: 2px;
}

.jso-booking-form .jso-autocomplete-item{
    padding: 0.5rem 1rem;
    border-bottom: 1px solid var(--jso-booking-form-border-color, #efefef);
}

.jso-booking-form .jso-autocomplete-item:last-child{
    border-bottom: 0;
}

.jso-booking-form-country-input{
    display: block !important;
}

.jso-booking-form-country-input.jso-autocomplete-input-mobile{
    display: none !important;
}

@media (max-width: 960px){
    .jso-booking-form-country-input.jso-autocomplete-input-mobile{
        display: block !important;
    }

    .jso-autocomplete-opened .jso-booking-form-country-input.jso-autocomplete-input-mobile{
        display: none !important;
    }
}

/**
 * check in & check out
 */
.jso-booking-check-in-out-content{
    user-select: none;
    display: flex;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
}

.jso-booking-check-in-out-content .jso-date-range-picker-text{
    width: 100%;
    border-right: 1px solid var(--jso-booking-form-border-color, #efefef);
    display: flex;
    padding: .5rem 1rem;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
} 

.jso-booking-check-in-out-content .jso-date-range-picker-text:last-child{
    border-right: 0;
}

.jso-booking-form .jso-date-range-picker-dropdown{
    margin-top: 2px;
    box-sizing: border-box;
    border-radius: var(--jso-booking-form-border-radius, 0.5rem);
    font-size: 0.9rem;
}

.jso-booking-form .jso-calendar-month-title{
    padding: 1rem;
    user-select: none;
}

.jso-booking-form .jso-date-range-picker-dropdown-content{
    padding: 0 0.5rem;
}

/*.jso-booking-form .jso-date-range-picker-dropdown-footer{
    padding: 0 1rem;
}*/

.jso-booking-form .jso-date-range-picker-footer-text{
    border-right: 1px solid #efefef;
    padding: 0.5rem 1rem;
    margin: 0;
}

.jso-booking-form .jso-date-range-picker-footer-text:nth-child(2){
    border-right: 0;
}

.jso-booking-form .jso-date-range-picker-nights-count{
    border-left: 1px solid var(--jso-booking-form-border-color, #efefef);
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jso-booking-form .jso-date-range-picker-popup .jso-popup-content-top{
    border-bottom: 1px solid var(--jso-booking-form-border-color, #efefef);
    padding-bottom: 0.3rem;
}

.jso-booking-form .jso-date-range-picker-popup .jso-popup-content-top h4{
    font-size: 1.2rem;
}

/**
 * guests
 */
.jso-booking-form-guests .jso-quantity-selector-fields{
    user-select: none;
    display: flex;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    cursor: pointer;
}

.jso-booking-form-guests .jso-quantity-selector-text{
    width: calc(50% - 1px);
    border-right: 1px solid var(--jso-booking-form-border-color, #efefef);
    display: flex;
    justify-content: space-between;
    padding: .5rem 1rem;
    align-items: center;
    white-space: nowrap;
}

.jso-booking-form-guests .jso-quantity-selector-text:last-child{
    border-right: 0;
}

.jso-booking-form .jso-quantity-selector-dropdown{
    margin-top: 2px;
    font-size: 0.9rem;
    user-select: none;
}

.jso-booking-form .jso-quantity-selector-popup .jso-popup-content-top{
    border-bottom: 1px solid var(--jso-booking-form-border-color, #efefef);
    padding-bottom: 0.3rem;
    margin-bottom: 0.5rem;
}

/**
 * search button
 */
.jso-booking-form-search-btn{
    width: 100%;

    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;

    box-shadow: #ccc 0 0 10px;
    background: var(--jso-booking-form-button-bg-color, #4e4e4e);
    color: var(--jso-booking-form-button-text-color, #fff);
    border-radius: 0.5rem;
    border: 0;

    padding: .8rem 2.5rem;
    margin: 0;

    font-weight: normal;
    font-size: 1.2rem;
    cursor: pointer;

    transition: 0.3s all;
}

.jso-booking-form-search-btn svg{
    margin-left: 0.5rem;
    transform: rotate(90deg);
    transition: 0.3s all;
}

.jso-booking-form-search-btn:hover svg{
    transform: rotate(90deg) translateY(-0.5rem);
}

/**
 * additional arrows
 */
.jso-booking-check-in-out-content .jso-date-range-picker-text:last-child::after,
.jso-booking-form-guests .jso-quantity-selector-text:last-child::after{
    content: '';
    display: block;
    width: 20px;
    height: 20px;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 512 512'%3E%3Ctitle%3Eionicons-v5-a%3C/title%3E%3Cpolyline points='112 268 256 412 400 268' style='fill:none;stroke:%231B2540;stroke-linecap:square;stroke-miterlimit:10;stroke-width:48px'%3E%3C/polyline%3E%3Cline x1='256' y1='392' x2='256' y2='100' style='fill:none;stroke:%231B2540;stroke-linecap:square;stroke-miterlimit:10;stroke-width:48px'%3E%3C/line%3E%3C/svg%3E") no-repeat 50% 50%;
    transition: 0.3s all;
}

.jso-booking-check-in-out.jso-date-range-picker-opened .jso-date-range-picker-text:last-child::after,
.jso-booking-form-guests.jso-quantity-selector-opened .jso-quantity-selector-text:last-child::after,
.jso-booking-check-in-out-content:hover .jso-date-range-picker-text:last-child::after,
.jso-booking-form-guests:hover .jso-quantity-selector-text:last-child::after{
    transform: rotate(-90deg);
}

/**
 * overlay once dropdown open
 */
.jso-booking-form .jso-autocomplete-opened ~ .jso-booking-check-in-out,
.jso-booking-form .jso-autocomplete-opened ~ .jso-booking-form-guests,
.jso-booking-form .jso-date-range-picker-opened ~ .jso-booking-form-guests{
    background: var(--jso-booking-form-overlay-bg-color, #7d7d7d);
    opacity: 0.4;
}

/**
 * validation errors:
 * jso-booking-form-error, jso-booking-form-country-error, jso-booking-check-in-out-error, jso-booking-form-guests-error

.jso-booking-form.jso-booking-form-country-error .jso-booking-form-country,
.jso-booking-form.jso-booking-check-in-out-error .jso-booking-check-in-out,
.jso-booking-form.jso-booking-form-guests-error .jso-quantity-selector{
    border: 1px solid var(--jso-booking-form-error-color, #ff0000);
} */

.jso-booking-form-error-msg{
    position: absolute;
    right: 0.5rem;
    top: 0.5rem;
    z-index: 10000;
    background: var(--jso-booking-form-error-color, #ff0000);
    color: #fff;
    font-size: 0.8rem;
    padding: 0.5rem;
    border-radius: 0.2rem;
}

.jso-booking-form-error-msg::before{
    content: '';
    width: 0;
    height: 0;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #a30000;
    position: absolute;
    top: calc(50% - 3px);
    left: -9px;
    transform: rotate(-90deg);
}

@media (min-width: 768px) {
    .jso-booking-form-error-msg{
        padding: 0.5rem 1rem;
    }

    .jso-booking-form-content h4 svg{
        margin-right: 1rem;
    }
}

/**
 * no shadow - used on colored backgrounds
 */
.jso-booking-form.jso-booking-form-no-shadow .jso-booking-check-in-out,
.jso-booking-form.jso-booking-form-no-shadow .jso-booking-form-country,
.jso-booking-form.jso-booking-form-no-shadow .jso-booking-form-guests,
.jso-booking-form.jso-booking-form-no-shadow .jso-booking-form-search-btn,
.jso-booking-form.jso-booking-form-no-shadow .jso-dropdown{
    box-shadow: none;
}

/**
 * flat style
 */
.jso-booking-form.jso-booking-form-flat{
    --jso-booking-form-border-color: #d2d2d2;
    --jso-booking-form-header-bg-color: #f7f7f7;
}

.jso-booking-form.jso-booking-form-flat .jso-booking-check-in-out,
.jso-booking-form.jso-booking-form-flat .jso-booking-form-country,
.jso-booking-form.jso-booking-form-flat .jso-booking-form-guests{
    box-shadow: none;
    border: 1px solid var(--jso-booking-form-border-color, #efefef);
}

.jso-booking-form.jso-booking-form-flat .jso-booking-form-search-btn{
    box-shadow: none;
}

.jso-booking-form.jso-booking-form-flat .jso-booking-form-search-btn{
    width: calc(100% - 2px);
    margin-left: auto;
    margin-right: auto;
}

/**
 * stripes style
 */
.jso-booking-form.jso-booking-form-stripes .jso-booking-form-title{
    background: repeating-linear-gradient(-55deg, #f7f7f7, #f7f7f7 10px, #eee 10px, #eee 20px), #f7f7f7;
    border-radius: 0.5rem 0.5rem 0 0;
}

/**
 * separator style
 */
.jso-booking-form.jso-booking-form-separator .jso-booking-form-title{
    background: linear-gradient(135deg, rgb(245, 245, 245) 0%, rgb(230, 230, 230) 50%, rgb(239, 239, 239) 51%, rgb(253, 253, 253) 100%);
    border-radius: 0.5rem 0.5rem 0 0;
}

/**
 * form on dark background styles
 */
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-search-btn{
    --jso-booking-form-button-bg-color: #b3b3b3;
    --jso-booking-form-button-bg-focus-color: #888;
    --jso-booking-form-button-text-color: #fff;
}

.jso-booking-form.jso-booking-form-dark-bg h3{
    color: #fff;
}

.jso-booking-form.jso-booking-form-dark-bg .jso-booking-check-in-out,
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-country,
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-guests,
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-search-btn{
    box-shadow: none;
}

/**
 * colored styles
 */
.jso-booking-form.jso-booking-form-colored{
    --theme-color-1: #fff;
    --theme-color-2: #fff;
    --theme-text-color-1: #111;
    --theme-text-color-2: #fff;
    --theme-thumb-color: #e4e4e4;
    --theme-error-bg-color: #a30000;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored .jso-booking-form-title{
    background-color: var(--theme-color-1, #fff);
    color: var(--theme-text-color-1, #111);
}

.jso-booking-form.jso-booking-form-colored .jso-date-range-picker-selected-day-from,
.jso-booking-form.jso-booking-form-colored .jso-date-range-picker-selected-day-from:hover,
.jso-booking-form.jso-booking-form-colored .jso-date-range-picker-selected-day-to,
.jso-booking-form.jso-booking-form-colored .jso-date-range-picker-selected-day-to:hover{
    background-color: var(--theme-color-1, #007bff);
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-stripes .jso-booking-form-title{
    background: repeating-linear-gradient(-55deg, var(--theme-color-2), var(--theme-color-2) 10px, var(--theme-color-1) 10px, var(--theme-color-1) 20px), var(--theme-thumb-color);
    color: var(--theme-text-color-2, #fff);
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-separator .jso-booking-form-title{
    background: linear-gradient(135deg, var(--theme-color-1) 50%, var(--theme-color-2) 51%, var(--theme-color-1) 100%);
    color: var(--theme-text-color-2, #fff);
}

.jso-booking-form.jso-booking-form-colored .jso-booking-form-error-msg{
    background-color: var(--theme-error-bg-color, #a30000);
    color: var(--theme-error-text-color, #fff);
}

.jso-booking-form.jso-booking-form-colored .jso-booking-form-error-msg::before{
    border-bottom-color: var(--theme-error-bg-color, #a30000);
}

/**
 * styles per color
 */
.jso-booking-form.jso-booking-form-colored.jso-booking-form-0000CC{
    --theme-color-1: #0000CC;
    --theme-color-2: #4545e4;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #d3faff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-003399{
    --theme-color-1: #003399;
    --theme-color-2: #1750c3;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #d3faff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-0066FF{
    --theme-color-1: #0066FF;
    --theme-color-2: #3888ff;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #fff;
    --theme-error-bg-color: #d3faff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-006666{
    --theme-color-1: #006666;
    --theme-color-2: #047979;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #c1ffdd;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-006699{
    --theme-color-1: #006699;
    --theme-color-2: #0f79af;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #d3faff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-0066CC{
    --theme-color-1: #0066CC;
    --theme-color-2: #127be4;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #fff;
    --theme-error-bg-color: #d3faff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-009966{
    --theme-color-1: #009966;
    --theme-color-2: #07aa74;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #0b2f1b;
    --theme-error-bg-color: #c1ffdd;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-009999{
    --theme-color-1: #009999;
    --theme-color-2: #04aaaa;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-3399CC{
    --theme-color-1: #3399CC;
    --theme-color-2: #3ba7de;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #082333;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-0099FF{
    --theme-color-1: #0099FF;
    --theme-color-2: #0187e0;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #193c6b;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-33CC00{
    --theme-color-1: #33CC00;
    --theme-color-2: #2cb100;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #fff;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-660099{
    --theme-color-1: #660099;
    --theme-color-2: #7a07b3;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #f1ddff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-6600CC{
    --theme-color-1: #6600CC;
    --theme-color-2: #5a06af;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #fff;
    --theme-error-bg-color: #eee1fb;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-663333{
    --theme-color-1: #663333;
    --theme-color-2: #7f4545;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #ffcece;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-666600{
    --theme-color-1: #666600;
    --theme-color-2: #737303;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #eef9c9;
    --theme-error-bg-color: #eef9c9;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-666699{
    --theme-color-1: #666699;
    --theme-color-2: #77a;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #000;
    --theme-error-bg-color: #eef9c9;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-6666CC{
    --theme-color-1: #6666CC;
    --theme-color-2: #7474da;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #050429;
    --theme-error-bg-color: #eef9c9;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-990000{
    --theme-color-1: #990000;
    --theme-color-2: #810101;
    --theme-text-color-1: #fff;
    --theme-error-bg-color: #f9d5d4;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-FF0000{
    --theme-color-1: #FF0000;
    --theme-color-2: #d40000;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #111;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-990066{
    --theme-color-1: #990066;
    --theme-color-2: #ad0675;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #222;
    --theme-error-bg-color: #fbd9d9;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-990099{
    --theme-color-1: #990099;
    --theme-color-2: #ad05ad;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #222;
    --theme-error-bg-color: #ffc9ff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-9900CC{
    --theme-color-1: #9900CC;
    --theme-color-2: #8301af;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #fff;
    --theme-error-bg-color: #ffc9ff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-9900FF{
    --theme-color-1: #9900FF;
    --theme-color-2: #8300da;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #000;
    --theme-error-bg-color: #f1ddff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-996600{
    --theme-color-1: #996600;
    --theme-color-2: #b17a0d;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #402b00;
    --theme-error-bg-color: #feffbe;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-9966CC{
    --theme-color-1: #9966CC;
    --theme-color-2: #a877da;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #4c2b6d;
    --theme-error-bg-color: #f1ddff;
    --theme-error-text-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-9999CC{
    --theme-color-1: #9999CC;
    --theme-color-2: #8c8cc3;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #353554;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-9999FF{
    --theme-color-1: #9999FF;
    --theme-color-2: #8a8aec;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #4c4da7;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-99CC00{
    --theme-color-1: #99CC00;
    --theme-color-2: #8bb903;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #5b7900;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-99CC99{
    --theme-color-1: #99CC99;
    --theme-color-2: #8abd8a;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #4b774b;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-99CCCC{
    --theme-color-1: #99CCCC;
    --theme-color-2: #8bbdbd;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #447171;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-99CCFF{
    --theme-color-1: #99CCFF;
    --theme-color-2: #8dbbea;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #53799e;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CC3399{
    --theme-color-1: #CC3399;
    --theme-color-2: #b92d8a;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #350927;
    --theme-error-bg-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CC00FF{
    --theme-color-1: #CC00FF;
    --theme-color-2: #b905e6;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #5e0075;
    --theme-error-bg-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CC6600{
    --theme-color-1: #CC6600;
    --theme-color-2: #bd6003;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #3e1f00;
    --theme-error-bg-color: #111;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CC9900{
    --theme-color-1: #CC9900;
    --theme-color-2: #bb8d02;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #5a4402;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CC9999{
    --theme-color-1: #CC9999;
    --theme-color-2: #b98787;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #6b3e3e;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CC99CC{
    --theme-color-1: #CC99CC;
    --theme-color-2: #b987b9;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #674167;
    --theme-error-bg-color: #671465;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CCCC99{
    --theme-color-1: #CCCC99;
    --theme-color-2: #bbbb87;
    --theme-text-color-1: #fff;
    --theme-thumb-color: #6f6f41;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-CCCCFF{
    --theme-color-1: #CCCCFF;
    --theme-color-2: #c0c0f4;
    --theme-text-color-1: #111;
    --theme-thumb-color: #646492;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-FF9933{
    --theme-color-1: #FF9933;
    --theme-color-2: #e48527;
    --theme-text-color-1: #111;
    --theme-thumb-color: #fff;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-FF9999{
    --theme-color-1: #FF9999;
    --theme-color-2: #e68282;
    --theme-text-color-1: #111;
    --theme-thumb-color: #7d3e3f;
    --theme-error-bg-color: #111;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-FFCC99{
    --theme-color-1: #FFCC99;
    --theme-color-2: #eab887;
    --theme-text-color-1: #111;
    --theme-text-color-2: #111;
    --theme-thumb-color: #92714a;
    --theme-error-bg-color: #69411a;
    --theme-error-text-color: #fff;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-FFCCCC{
    --theme-color-1: #FFCCCC;
    --theme-color-2: #ecb8b8;
    --theme-text-color-1: #111;
    --theme-text-color-2: #111;
    --theme-thumb-color: #a27b7c;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-FFCCFF{
    --theme-color-1: #FFCCFF;
    --theme-color-2: #eebaee;
    --theme-text-color-1: #111;
    --theme-text-color-2: #111;
    --theme-thumb-color: #8f6594;
}

/**
 * horizontal layout
 */
@media (min-width: 1000px){
    .jso-booking-form.jso-booking-form-horizontal{
        width: 100%;
    }

    .jso-booking-form.jso-booking-form-horizontal .jso-booking-form-content{
        flex-direction: row;
        justify-content: center;
    }

    .jso-booking-form.jso-booking-form-horizontal .jso-booking-form-search-btn{
        width: 175px;
    }

    .jso-booking-form.jso-booking-form-horizontal .jso-booking-check-in-out,
    .jso-booking-form.jso-booking-form-horizontal .jso-booking-form-country,
    .jso-booking-form.jso-booking-form-horizontal .jso-booking-form-guests{
        margin: 0 1rem 0 0;
        width: 350px;
    }

    .jso-booking-form.jso-booking-form-horizontal .jso-date-range-picker-dropdown{
        background: #fff;
        width: auto;
    }

    .jso-booking-form.jso-booking-form-horizontal .jso-autocomplete-opened ~ .jso-booking-check-in-out,
    .jso-booking-form.jso-booking-form-horizontal .jso-autocomplete-opened ~ .jso-booking-form-guests,
    .jso-booking-form.jso-booking-form-horizontal .jso-date-range-picker-opened ~ .jso-booking-form-guests{
        background: var(--jso-booking-form-bg-color, #fff);
        opacity: 1;
    }

    .jso-booking-form.jso-booking-form-horizontal.jso-booking-form-flat .jso-booking-form-search-btn{
        margin: 0;
    }
}

/**
 * desktop special styles
 */
@media (min-width: 768px) {
    .jso-booking-form h3 {
        font-size: 2.8rem;
    }
}

/**
 * focus and active state
 */

/* in case of focus -> all sections get a dark shadow */
.jso-booking-check-in-out:active,
.jso-booking-check-in-out:focus,
.jso-booking-form-guests:active,
.jso-booking-form-guests:focus{
    outline: none !important;
    box-shadow: 0 0 0.5rem var(--jso-booking-form-focus-shadow-color) !important;
}

.jso-booking-form-country:focus-within{
    box-shadow: 0 0 0.5rem var(--jso-booking-form-focus-shadow-color) !important;
}

/* in case of focus -> submit button gets a dark shadow and a darker background color */
.jso-booking-form-search-btn:hover,
.jso-booking-form-search-btn:focus,
.jso-booking-form-search-btn:active{
    outline: none !important;
    box-shadow: 0 0 0.5rem var(--jso-booking-form-focus-shadow-color) !important;
    background: var(--jso-booking-form-button-bg-focus-color, #111) !important;
}

/* in dark background -> all sections get a light shadow */
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-check-in-out:active,
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-check-in-out:focus{
    box-shadow: 0 0 0.5rem #fff !important;
}

/* in dark background -> the submit button gets a lighter background color */
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-search-btn:hover,
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-search-btn:focus,
.jso-booking-form.jso-booking-form-dark-bg .jso-booking-form-search-btn:active{
    outline: none !important;
    background: var(--jso-booking-form-button-bg-focus-color, #666) !important;
}