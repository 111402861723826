.guaranteed-safe-container {
    margin-top: 32px;

    .guaranteed-text {
        color: #676767;
        font-family: 'Montserrat';
        font-size: 14px;
        line-height: 22.4px;
    }

    .guaranteed-safe-icons {
        display: flex;
        gap: 24px;
        flex-wrap: wrap;

        .green-icon-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 5.5px;

            .green-icon-overlay {
                position: relative;
                width: 22px;
                height: 25.5px;

                .shield {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                
                .check {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    width: 50%;
                    height: 50%;
                }
            }

            .green-text {
                color: #009F3C;
                font-family: 'Montserrat';
                font-size: 8px;
                font-weight: 600;
                line-height: 12.8px;
            }
        }

        .icon-container {
            height: 40px;
            width: 56px;
            background-color: #FFFFFF;

            img {
                height: 100%;
                width: 100%;
                object-fit: cover;
            }
        }
    }
}