.loginRegisterMainContainer.container {
    margin-top: 90px;
    max-width: 832px;
    background-color: #FFFFFF;
    padding: 48px;

    .loginRegisterTitle {
        color: #1B2540;
        font-size: 56px;
        line-height: 56px;
        font-family: 'Cormorant Garamond';
        text-align: center;
    }

    .label {
        color: #000000;
        font-family: 'Montserrat';
        font-size: 16px;
        line-height: 25.6px;
        margin-bottom: 0 !important;
    }

    .inputContainer {
        margin-top: 20px !important;
    }

    @media screen and (max-width: 768px) {
        .inputContainer.inputContainerRegister {
            flex-direction: column;
            gap: 20px
        }
    }

    .loginRegisterPage {
        .logInText {
            color: #676767;
            line-height: 25.6px;
            font-size: 16px;
            margin-top: 16px;
            margin-bottom: 16px;
            text-align: center;
            font-family: 'Montserrat';
        }

        .emailContainer {
            margin-top: 32px;
        }

        .input {
            padding: 12px;
            margin-top: 9px;
            border: 1px solid #9FB0BA;
            color: #000 !important;
            font-family: 'Montserrat';
            font-size: 16px;
            line-height: 25.6px;
            border-radius: initial;

            &:focus {
                outline: none;
                box-shadow: none;
                border: 1px solid #9FB0BA;
                box-shadow: 0 0 0 0.1rem #1B2540!important;
            }

            &:hover {
                outline: none;
                box-shadow: none;
                border: 1px solid #9FB0BA
            }
        }
        

        .input::placeholder {
            color: #676767 !important;
        }

        .errorMsg {
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 400;
            line-height: 22.4px;
            color: #676767;
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 9px;
        }

        .passwordContainer {
            margin-top: 20px;
        }

        .rememberMeContainer {
            margin-top: 20px;

            .rememberMeFlex {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .label.form-check {
                    display: flex;
                    align-items: center;

                    label.form-check-label {
                        font-size: 14px;
                        font-family: 'Montserrat';
                        color: #000000;
                        line-height: 22.4px;
                    }
                }
            }

            label {
                margin-top: 10px;
            }

            input[type="checkbox"] {
                height: 24px;
                width: 24px !important;
                background-color: initial;
                border-color: #9FB0BA;
                color: #676767 !important;

                &:focus {
                    outline: none;
                    box-shadow: none;
                    box-shadow: 0 0 0 0.1rem #1B2540 !important;
                }
        
                &:hover {
                    outline: none;
                    box-shadow: none;
                }

                &:checked {
                    background-color: #0d6efd;
                    color: #FFFFFF;
                    border:none
                }
            }

            span.forgot-password {
                color: #1B2540;
                font-family: 'Montserrat';
                font-size: 12px;
                font-weight: 500;
                line-height: 12px;
                text-transform: uppercase;
                cursor: pointer;
            }

            @media screen and (max-width: 768px) {
                .rememberMeFlex {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 24px;
                    justify-content: flex-start;
                }

            }
        }

        .buttonsContainer {
            margin-top: 32px;

            .button {
                width: 100%;;
                padding: 22px;
                border-radius: initial;
                border: none;
                font-family: 'Montserrat';
                font-weight: 500;
                font-size: 12px;
                line-height: 12px;
                text-transform: uppercase;
            }

            .button.login {
                color: #FFFFFF;
                background-color: #1B2540;
            }

            .button.signup {
                color: #1B2540 !important;
                display: inline-block;
                text-align: center;
                margin-top: 16px;
                background-color: rgba(27, 37, 64, 0.20); 
            }
        }
    }
}

.contact-section {
    margin-top: 90px !important;
}

@media screen and (max-width: 768px) {
    .contact-section {
        margin-top: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .loginMainContainer.container {
        margin-top: 0;
        padding: 48px 20px 48px 20px;
    }
}