.loader {
    background: rgba(0, 0, 0, 0.8);
    height:     100%;
    left:       0;
    position:   fixed;
    top:        0;
    width:      100%;
    z-index:    100000;
}

.loader__inner {
    height:   100%;
    left:     0;
    position: absolute;
    top:      0;
    width:    100%;
}

.loader__content {
    left:      50%;
    position:  absolute;
    top:       50%;
    transform: translate(-50%, -50%);
}

.loader__content .spinner {
    animation:        spin 1s infinite linear;
    border-color:     rgba(255, 255, 255, 0.75);
    border-radius:    100%;
    border-style:     solid;
    border-top-color: black;
    border-width:     2px;
    display:          inline-block;
    height:           75px;
    width:            75px;
}

@keyframes spin {
    100% {
        transform: rotate(360deg);
    }
}