.footer {
    a {
        color: #AAAAAA;
    }
}


.footer-logo {
    width: 224.37px;
}

.footer-text {
    color:       #AAAAAA;
    font-size:   14px;
    font-weight: 400;
}

.air{
    margin-left: 56px;
    width: 178.7px;
    height: 70px;
}

.rating{ 
    width: 100.55px;
    height: 70px;
}

.google{
    width: 126.43px;
    height: 70px;
    margin-left: 56px;
}

.aspca {
    width: 169.13px;
    height: 48px;
}

.footer-list {
    gap: 148px;
    width: fit-content;
}
.footer-list-block {
    width: fit-content;
}

.charities {
    color: white;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: 500;
}

.title{ 
    font-size: 18px;
    font-weight: 500;
}

.home{ 
    line-height: 28px;
}

.footer-copyright {
    display: flex;
    align-items: center;
    background-color: #1C1D20;
    box-shadow:       inset 0px 1px 0px rgba(102, 102, 102, 0.24);
    color:            rgba(255, 255, 255, 0.39);
    margin-bottom:    0px;
    transition:       background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
    height: 60px;

    .row > div:last-child {
        text-align: right;
    }


    @media only screen and (max-width: 768px) {

        > div {
            text-align: center;
        }
    }

    img {
        height:       24px;
        margin-right: 2rem;
    }
}

.footer-social-icons {
    height: fit-content;
    gap: 25px;
    img {
        margin: 0;
        width: 16px;
    }
    .fc {
        width: 21px;
    }
}

.footer-copyright-text {
    display: flex;
    align-items: center;
    justify-content: end;
    color: rgba(255, 255, 255, 39%);
    min-width: max-content;
    a {
        color: rgba(255, 255, 255, 39%);
        display: flex;
        align-items: center;
        min-width: max-content;
    }
    font-size: 14px;
    .bee {
        margin: 0;
        margin-left: 1px;
    }
}

@media (max-width: 992px) {

    .footer-copyright {
        margin-bottom: 60px !important;
    }
}

@media (max-width: 370px) {

  .charities-images {
    > img {
        width: 100px !important;
    }
  }
}

@media (max-width: 576px) {
    .footer-list {
        gap: 0;
        flex-direction: column;
        h4 {
            text-transform: uppercase;
            margin-bottom: 20px;
        }
    }
    .footer-logo {
        width: 160px;
    }
    .footer-text {    
        font-size: 12px;
    }
    .rating {
        width: 57.45px;
    }
    .air {
        width: 102px;
        margin-left: 46px;
    }
    .google {
        width: 72px;
        margin-left: 46px;
    }
    .list-unstyled {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 28px;
        li {
            margin-right: 28px;
        }
    }
    .charities {
        margin-bottom: 20px;
    }
    .charities-images {
        img {
            width: 116px;
        }
    }
    .footer-copyright-text {
        justify-content: left;
        margin-bottom: 20px;
    }
    .footer-social-icons {
        margin-bottom: 20px;
    }
    .footer-copyright {
        height: 96px;
        padding-top: 15px;
    }
}

@media (max-width: 1400px) and (min-width: 1200px) {
    .footer {
        > div {
            flex-wrap: nowrap !important;
        }
    }
    .footer-list {
        gap: 90px;
    }
}


@media (max-width: 350px) {
    .left-icons {
        > img  {
            width: 60px !important;
        }
    }

    .charities-images {
        > img {
            width: 90px !important;
        }
      }
}