@import '../../../App.scss';

.card {
  border: none !important;
}

.tableCard {
  background: transparent;
  border-color: transparent;
  border-radius: 0 !important;
  margin-bottom: 2rem;
  transition: all 200ms;
  width: 100%;
  height: full;

  &:hover {
    transform: scale(1.02);

    .view-more-btn.btn {
      background-color: $base-blue;
      color: white !important;
    }
  }

  .blueRow {
    display: flex;
    justify-content: space-between;
    padding: 0.8rem 1.5rem;
    text-align: center;
  }

  .background {
    background-position: center;
    background-size: cover;
    height: 195px;
  }
  .tableCardBodyTitleWrapper {
    display: flex;
  }

  .tableCardBodyTitle {
    font-weight: 400;
    line-height: 38.4px;
    letter-spacing: -2%;
    font-size: 32px;
  }

  .tableCardBodyRating {
    display: flex;
    align-items: center;
    height: fit-content;
    padding-left: 20px;
    gap: 5px;
    * {
      color: $base-blue !important;
    }
    span {
      font-size: 18px;
      font-weight: 400;
      line-height: 28.8px;
      font-family: 'Montserrat';
    }
  }

  .heart-icon {
    position: absolute;
    color: $base-blue;
    background: #ffffff80;
    border-radius: 50%;
    width: 48px;
    height: 48px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 24px 0 0 24px;
    font-size: 26px;
    z-index: 1;
  }

  .tableCardBody {
    background-color: white;
    color: #dddddd;
    font-size: 14px;
    padding: 25px;
    position: relative;

    .col {
      white-space: nowrap;
    }

    .row{
      display: flex;
      align-items: start;
      justify-content: space-between;
    }

    .row.card-price-main-container {
      @media only screen and (max-width: 1000px) {
        align-items: center;
      }
    }

    h1 {
      color: $base-blue;
      font-size: 32px;
      font-weight: 400;
      line-height: 35px;
      font-family: 'Cormorant Garamond', sans-serif;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Cormorant Garamond';
      font-size: 32px;
      font-weight: 400;
      line-height: 1.2;
      letter-spacing: -0.02em;
      margin: 0;
      height: 76px;
    }

    .price-subtitle {
      color: #727475;
      font-size: 12px;
      white-space: nowrap;
    }

    .view-more-btn {
      width: 100%;
      color: $base-blue !important;
      background: transparent;
      border-radius: 0;
      border-color: $base-blue;
      font-size: 12px;
      font-weight: 500;
      line-height: 12px;
      font-family: 'Montserrat';
      padding-top: 12px;
      padding-bottom: 12px;
    }
    .price-wrapper2 {
      display: flex;
      align-items: start;
      gap: 3px;

      @media only screen and (max-width: 1000px) {
        flex-direction: column;
        gap: 8px
      }
    }

    .price-wrapper {
      width: calc(100% - 125px);
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 8px;
    }
    .price-wrapper-button{
      width: 125px;
      padding: 0;
    }

    .price-subtitle {
     font-family: 'Montserrat';
    }

    .price {
      font-size: 20px !important;
      color: $base-blue;
      font-family: 'Montserrat';
    }

    .price-total-container {
      display: flex;
      align-items: center;
      color: #383a40;
      line-height: 22.4px;
      gap: 5px;
      height: 22px;
      span.total-price {
        font-size: 14px;
        font-weight: 400;
        color: #1B2540;
        opacity: 0.7;
        line-height: 22.4px;
        white-space: nowrap;
        font-family: 'Montserrat';
        border-bottom: 1px solid rgba(27, 37, 64, 0.7);
      }

      span.price-dot {
        @media only screen and (max-width: 1000px) {
          display: none;
        }
      }
    }

    @media only screen and (max-width: 768px) {
      .price {
        font-size: 20px;
        font-weight: 500;
        line-height: 32px;
        color: #1B2540;
      }

      .price-subtitle {
        font-size: 11px;
      }
    }

    img {
      height: 20px;
      margin-right: 4px;
      width: 20px;
    }

    .amenity-list {
      width: auto;
      display: flex;
      align-items: center;
      gap: 3px;
      color: #1B2540;
      font-size: 12px;
      font-weight: 600;
      line-height: 19.2px;
      padding: 0;
    }


    .tableCardMeta {
      display: flex;
      align-items: center;
      gap: 7px;
      margin: 25px 0 40px 0;
      font-size: 0.9rem;
      color: $base-blue;
      font-family: 'Montserrat';
      svg {
        margin-right: 5px;
      }
      @media only screen and (max-width: 768px) {
        font-size: 0.8rem;
      }

      @media only screen and (max-width: 320px) {
        svg {
          display: none;
        }
      }
    }
  }

  @media only screen and (max-width: 374px) {
    .price-wrapper-button{
      width: 110px !important;
    }
    .btn{
      font-size: 15px !important;
    }
  }

  .tableCardHeader {
    background-repeat: no-repeat;
    background-size: cover !important;
    // border-radius:     24px 24px 0 0;
    color: white;
    display: flex;
    font-size: 11px;
    height: 360px;
    letter-spacing: 2px;
    text-transform: uppercase;
    cursor: pointer;

    img {
      height: 10px;
      margin-bottom: 6px;
      margin-left: 6px;
      width: 10px;

      &:first-child {
        margin-left: 16px;
      }
    }

    > div {
      background: -moz-linear-gradient(to bottom, rgba(28, 28, 28, 0) 60%, rgba(28, 28, 28, 0.5) 100%);
      background: -webkit-linear-gradient(to bottom, rgba(28, 28, 28, 0) 60%, rgba(28, 28, 28, 0.5) 100%);
      background: linear-gradient(to bottom, rgba(28, 28, 28, 0) 60%, rgba(28, 28, 28, 0.5) 100%);
      display: flex;
      filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00656460', endColorstr='#a6656460', GradientType=0);
      height: 100%;
      vertical-align: middle;
      width: 100%;



      > div:last-child {
        align-items: flex-end;
        display: flex;
        height: 100%;
      }
    }
  }
}
