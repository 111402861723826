@import '../../App.scss';

.footer {
  a {
    color: #aaaaaa;
  }
}

.footer-logo {
  width: 224.37px;
}

.footer-text {
  color: #aaaaaa;
  font-size: 14px;
  font-weight: 400;
}

.air {
  margin-left: 56px;
  width: 178.7px;
  height: 70px;
}

.rating {
  width: 100.55px;
  height: 70px;
}

.google {
  width: 126.43px;
  height: 70px;
  margin-left: 56px;
}

.aspca {
  width: 169.13px;
  height: 48px;
}

.half-flex-space {
  flex: 0 0 48%;
}
.footer-list-block {
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
}

.charities {
  color: white;
  text-transform: uppercase;
  font-size: 18px;
  font-weight: 500;
}

.title {
  font-size: 16px;
  font-weight: 500;
  font-family: 'Montserrat';
  line-height: 25.6px;
}

.home {
  line-height: 28px;
}

.footer-copyright {
  display: flex;
  align-items: center;
  background-color: #1c1d20;
  box-shadow: inset 0px 1px 0px rgba(102, 102, 102, 0.24);
  color: rgba(255, 255, 255, 0.39);
  margin-bottom: 0px;
  transition: background 0.3s, border 0.3s, border-radius 0.3s, box-shadow 0.3s;
  height: 60px;

  .row > div:last-child {
    text-align: right;
  }

  @media only screen and (max-width: 768px) {
    > div {
      text-align: center;
    }
  }

  img {
    height: 24px;
    margin-right: 2rem;
  }
}

.footer-social-icons {
  height: fit-content;
  gap: 25px;
  img {
    margin: 0;
    width: 16px;
  }
  .fc {
    width: 21px;
  }
}

.footer-copyright-text {
  display: flex;
  align-items: center;
  justify-content: end;
  color: rgba(255, 255, 255, 39%);
  min-width: max-content;
  a {
    color: rgba(255, 255, 255, 39%);
    display: flex;
    align-items: center;
    min-width: max-content;
  }
  font-size: 14px;
  .bee {
    margin: 0;
    margin-left: 1px;
  }
}

.contact-section {
  background-color: $base-blue;
  padding: 110px 0;

  @media only screen and (max-width: 768px) {
    padding: 72px 8px;
  }
}

.contact-wrapper {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.message-input {
  width: 100%;
  border: 0 !important;
  border-radius: 0 !important;
  background-color: transparent !important;
  border-bottom: 1px solid #ffffff1a !important;
  max-height: 40px;
  margin-bottom: 24px;
  font-family: 'Montserrat';
  font-size: 16px;
  line-height: 24px;

  @media only screen and (max-width: 768px) {
    padding: 16px !important;
    &::placeholder {
      color: #F3F5FB;
    }
  }
}

@media only screen and (max-width: 768px) {
  textarea.message-input {
    height: 130px;
    max-height: 100% !important;
  }
}

.message-label {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  color: #FFFFFF;

  @media only screen and (max-width: 768px) {
    display: none;
  }
}

.half-size {
  width: 50%;
}

.phone-email-wrapper {
  display: flex;
  justify-content: space-between;

  @media only screen and (max-width: 768px) {
    flex-direction: column;
  }
}

.info-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px
}

.message-send-btn {
  width: 100%;

  &:disabled {
    background-color: #676767;
    cursor: auto;
  }
}

.contact-info {
  max-width: 600px;
  font-size: 16px;
  font-family: 'Montserrat';
  color: #F3F5FB;
}

.contact-title {
  font-family: 'Cormorant Garamond', sans-serif;
  font-size: 5rem;
  font-weight: 500;
  color: #EDEBE7;

  @media only screen and (max-width: 768px) {
    font-size: 40px;
  }
}

.contact-links {
  list-style-type: none;
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  padding: 0;
  li {
    border: 1px solid #FFFFFF;
    padding: 4px 16px;

    a {
      color: white;
      text-transform: uppercase;
      font-family: 'Montserrat';
      font-weight: 500;
      font-size: 12px;
      line-height: 12px;
    }
  }
}

.address-title {
  text-transform: uppercase;
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
}

.address {
  font-weight: 400;
  font-size: 16px;
  font-family: 'Montserrat';
  font-weight: 400;
  color: #F3F5FB;

  span {
    border-bottom: 1px solid #F3F5FB;
  }

  a {
    color: #F3F5FB !important
  }
}

.working-hours {
  font-weight: 500;
  font-size: 12px;
  font-family: 'Montserrat';
  text-transform: uppercase;
  color: #FFFFFF;
  opacity: 0.5;
  margin-bottom: 0;
}

.agreement-checkbox-container {
  margin: 20px 0;
  font-size: 14px;
  font-family: 'Montserrat';
  color: #F3F5FB;

  .checkbox-wrapper {
    display: flex;
    align-items: baseline;
    position: relative;

    .agreement-checkbox {
      height: 12px;
      width: 12px !important;
    }

    .agreement-checkbox::before {
      content: "";
      display: block;
      position: absolute;
      text-align: center;
      height: 12px;
      width: 12px;
      left: 0;
      background-color: #1B2540;
      font-family: "Montserrat";
      border-radius: 2px;
      border: 1px solid rgb(150 150 150 / 30%);
    }

    .agreement-checkbox:checked+label::after {
      content: url('data:image/svg+xml; utf8, <svg xmlns="http://www.w3.org/2000/svg" width="10" height="10" fill="white" viewBox="0 0 24 24"><path d="M20.285 2l-11.285 11.567-5.286-5.011-3.714 3.716 9 8.728 15-15.285z"/></svg>');
      display: block;
      position: absolute;
      left: 1px;
      top: 0;
    }
  }
}

.message-send-btn-container {
  @media only screen and (max-width: 768px) {
    display: flex;
    justify-content: end;
  }

  .message-send-btn {
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
    padding: 10px;
    background-color: white;
    border: 1px solid $base-blue;
    color: $base-blue;
    font-family: 'Montserrat';
    font-weight: 500;
    font-size: 12px;
    height: 47px;

    @media only screen and (max-width: 768px) {
      font-weight: 600;
      font-size: 14px;
      width: 50%;
      padding: 16px 48px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}

.text-base-blue {
  color: $base-blue;
}

.grey-list-items {
  font-size: 18px;
  font-weight: 400;
  font-family: 'Montserrat';
  line-height: 28.8px;

  li {
    padding: 10px 0;
  }
  a {
    color: #676767;
  }
}

.footer-menu-section {
  margin-top: 110px;
}

.footer-logo-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 100px;
  margin-top: 120px;

  @media only screen and (max-width: 768px) {
    margin-top: 24px;

    svg {
      width: 50%;
      height: 50%;
    }
  }
}

.about {
  display: flex;
  justify-content: space-between;
  font-family: 'Montserrat';

  @media only screen and (max-width: 768px) {
    flex-direction: column;
    gap: 32px;
  }
}

.about-text {
  color: #676767;
  font-weight: 400;
  font-size: 14px;
}

.about-why-title {
  color: $base-blue;
  font-weight: 500;
  font-size: 16px;
}

.why-us-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  gap: 10px;
  flex-wrap: wrap;
  li {
    margin-right: 20px;
    white-space: nowrap;
  }
  span {
    padding-left: 10px;
    color: #676767;
  }

  @media only screen and (max-width: 768px) {
    gap: 20px;
  }
}

.form-field-agreements-label {
  a {
    color: #f3f5fb;
    text-decoration: underline;
    display: inline-block;
  }
}

.footer-list {
  gap: 100px;
  width: -moz-fit-content;
  width: fit-content;
}

@media (max-width: 992px) {
  .footer-copyright {
    margin-bottom: 60px !important;
  }
}

@media (max-width: 370px) {
  .charities-images {
    > img {
      width: 100px !important;
    }
  }
}

@media (max-width: 576px) {
  .footer-list {
    gap: 0;
    max-width: 600px;
    flex-direction: column;
    h4 {
      text-transform: uppercase;
      margin-bottom: 20px;
    }
  }
  .footer-logo {
    width: 160px;
  }
  .footer-text {
    font-size: 12px;
  }
  .rating {
    width: 57.45px;
  }
  .air {
    width: 102px;
    margin-left: 46px;
  }
  .google {
    width: 72px;
    margin-left: 46px;
  }
  .list-unstyled {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 28px;
    li {
      margin-right: 28px;
    }
  }
  .charities {
    margin-bottom: 20px;
  }
  .charities-images {
    img {
      width: 116px;
    }
  }
  .footer-copyright-text {
    justify-content: left;
    margin-bottom: 20px;
  }
  .footer-social-icons {
    margin-bottom: 20px;
  }
  .footer-copyright {
    height: 96px;
    padding-top: 15px;
  }
}

@media (max-width: 1400px) and (min-width: 1200px) {
  .footer {
    > div {
      flex-wrap: nowrap !important;
    }
  }
  .footer-list {
    gap: 90px;
  }
}

@media (max-width: 350px) {
  .left-icons {
    > img {
      width: 60px !important;
    }
  }

  .charities-images {
    > img {
      width: 90px !important;
    }
  }
}
.contact-section {
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    -webkit-text-fill-color: white !important;
  }
}
