.sorrentoPH {
    .fb-menu {
        .makLogoTop {
            margin-bottom: 0;
        }

        @media screen and (max-width: 576px) {
            display: none;
        }
    }
    .sorrento-menu-mobile {
        padding: 24px 16px;
        position: absolute;
        width: 100%;
        z-index: 8;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .back-btn-and-logo {
            display: flex;
            align-items: center;
            gap: 16px;

            .mobile-menu-back-btn {
                height: 24px;
                width: 24px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .start-tour-and-menu-btn {
            display: flex;
            align-items: center;
            gap: 16px;

            .mobile-menu-tour-btn {
                font-weight: 500;
                padding: 16px 20px;
                color: #072437;
                letter-spacing: 0.24px;
                line-height: 12px;
                font-family: 'Montserrat';
            }
        }
    }
    .fb-welcome-gradient {
        background: linear-gradient(180deg, rgba(0, 3, 23, 0.50) 0%, rgba(0, 3, 23, 0.00) 100%);
    }
    .fb-welcome-carusel-textbox {
        h1 {
            @media only screen and (max-width: 576px) {
                font-size: 44px;
                line-height: 39.6px;
            }
        }
        p {
            @media only screen and (max-width: 576px) {
                font-size: 14px;
                font-weight: 500;
                line-height: 14px;
            }
        }
    }
    .fb-menu-text {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(248, 246, 244, 0.20);
        padding: 16px 20px;
        font-family: "Montserrat";
        font-weight: 500;
        font-size: 12px;
        line-height: 12px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
    }

    .fb-menu-buttonDiv {
        .fb-menu-buttonDiv-button {
            color: #072437;
            line-height: 12px;
            padding: 16px 20px;
            font-family: 'Montserrat';
            font-weight: 500;
            letter-spacing: 0.24px;
        }
    }
    .fb-welcome-buttonDiv {
        .fb-welcome-buttonDiv-button {
            color: #072437;
            line-height: 12px;
            padding: 18px 24px;
            font-family: 'Montserrat';
            font-weight: 500;
            letter-spacing: 0.24px;
            background-color: #FFFFFF;
            width: 240px;
        }
    }

    .fb-startTour {
        padding-top: 100px;

        @media screen and (max-width: 768px) {
            padding-top: 60px;
        }
    }

    .fb-startTour-title {
        @media only screen and (max-width: 576px) {
            font-size: 44px;
            line-height: 39.6px;
        }
    }

    .fb-startTour-items {
        padding: 64px 96px 0 96px;

        @media screen and (max-width: 768px) {
            padding: 32px 20px 0 20px;
        }
    }

    .ph-page-date-picker-wrapper {
        margin-bottom: 64px;
        .jso-booking-check-in-out-content {
            background-color: #F7F4F0 !important;

            @media screen and (max-width: 768px) {
                flex-direction: row;
            }
        }

        .jso-date-range-picker-text {
            text-transform: uppercase;

            .checkinout-text {
                text-transform: capitalize;
                margin-bottom: 8px !important;
            }

            @media screen and (max-width: 768px) { 
                padding: 16px 20px !important;
                font-size: 16px !important;
            }
        }

        .jso-date-range-picker-text:nth-of-type(1) {
            @media screen and (max-width: 768px) {
                border-bottom: none !important;
                border-right: 1px solid rgba(27, 37, 64, 0.20) !important;
            }
        }
    }

    .fb-startTour-items-item-body {
        margin-top: 16px;
    }

    .fb-startTour-items-item-footer {
        margin-bottom: 70px;

        @media screen and (max-width: 768px) {
            margin-bottom: 30px;
        }
    }

    .fb-startTour-items-item-header {
        .fb-startTour-items-item-header-priceDiv {
            .fb-startTour-items-item-header-priceDiv-price-text1 {
                font-size: 24px;
                line-height: 38.4px;

                @media screen and (max-width: 768px) {
                    font-size: 20px;
                    line-height: 32px;
                }
            }
        }

        .fb-startTour-items-item-header-priceDiv-flexCol-button {
            padding: 21px 24px;
            line-height: 12px;
            letter-spacing: 0.24px;
            width: 107px;
        }

        .fb-startTour-items-item-header-priceDiv-price-text3 {
            color: #072437;
        }
    }

    @media screen and (max-width: 768px) {
        margin-bottom: 32px;
        .fb-startTour-items-item-header-title {
            text-align: left;
            line-height: 32px;
            letter-spacing: -2.4px;
        }
    }

    .fb-startTour-items-item-body {
        .fb-startTour-items-item-body-image {
            height: 814px;
        }
    }

    .section {
        .section-gradient {
            background: linear-gradient(180deg, rgba(0, 3, 23, 0.40) 26.12%, rgba(0, 3, 23, 0.00) 84.32%) !important;
        }
        .section-inner {
            h2 {
                line-height: 57.6px;

                @media screen and (max-width: 768px) {
                    font-size: 44px;
                    line-height: 39.6px;
                }
            }

            p {
                line-height: 19.2px;

                @media screen and (max-width: 768px) {
                    font-size: 14px;
                    line-height: 14px;
                }
            }
        }
        .section-inner.sec-4 {
            backdrop-filter: initial !important;
        }
    }

    .events {
        background-color: #F7F4F0;
        padding: 100px 48px;
        @media only screen and (max-width: 576px) {
            padding: 60px 20px;
        }
        .header {
            color: #1B2540;
            text-align: center;
            font-family: 'Cormorant Garamond';
            font-size: 64px;
            font-weight: 400;
            line-height: 57.6px;
            letter-spacing: -3.2px;
            text-transform: uppercase;
            padding: 0px;

            p {
                margin-bottom: 8px;
            }

            @media only screen and (max-width: 576px) {
                font-size: 44px;
                line-height: 39.6px;
            }
        }
        .desc-view {
            margin-top: 24px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 24px;
        }
        .events-desc {
            color: #1B2540;
            text-align: center;
            font-family: "Montserrat";
            font-size: 16px;
            font-weight: 500;
            line-height: 19.2px;
            letter-spacing: 0.32px;
            text-transform: uppercase;

            @media only screen and (max-width: 576px) {
                font-size: 14px;
                line-height: 14px;
            }

            p {
                margin-bottom: 0;
            }
        }
        .events-view {
            color: #EFEFEF;
            background-color: #072437;
            border: none;
            text-align: center;
            font-family: "Montserrat";
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.24px;
            text-transform: uppercase;
            display: flex;
            padding: 16px 20px;
            justify-content: center;
            align-items: center;
            cursor: pointer;
        }
        .events-show-desktop {
            margin-top: 60px;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 60px 48px;

            .event-card {
                .event-img {
                    height: 690px;
                    width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }
                .card-footer {
                    padding: 40px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .title {
                        color: #78572C;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.4px;
                        text-transform: uppercase;
                    }

                    .text {
                        color: #78572C;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        text-transform: capitalize;
                    }
                }
            }
        }
        .events-show-mobile {
            margin-top: 40px;

            .event-card-mobile {
                .img {
                    height: 500px;
                    width: 100%;

                    img {
                        height: 100%;
                        width: 100%;
                        object-fit: cover;
                    }
                }

                .mobile-card-footer {
                    padding: 40px 20px;
                    display: flex;
                    flex-direction: column;
                    gap: 16px;

                    .title {
                        color: #78572C;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.4px;
                        text-transform: uppercase;
                    }
                    .text {
                        color: #78572C;
                        font-family: Montserrat;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        text-transform: capitalize;
                    }
                }
            }

            .carousel-indicators {
                button {
                    background-color: #1B2540;
                    opacity: 0.2;
                }

                button.active {
                    opacity: 1;
                }
            }
        }
    }

    .map {
        h2 {
            color: #1B2540;
        }
        @media screen and (max-width: 576px) {
            padding: 60px 0;
            h2 {
                font-size: 44px;
                line-height: 39.6px;
            }
        }

        p, .map-button {
            margin-bottom: 24px;
        }
    }

    .sorrento-extras {
        .extras {
            @media screen and (max-width: 576px) {
                padding: 60px 0;
            }
            background-color: #F7F4F0;
            .extras-textbox {
                p {
                    color: #1B2540;
                    line-height: 19.2px;

                    @media screen and (max-width: 576px) {
                        font-size: 14px !important;
                        line-height: 14px !important;
                    }
                }
                h2 {
                    color: #1B2540;

                    @media screen and (max-width: 576px) {
                        font-size: 44px;
                        line-height: 39.6px;
                        letter-spacing: -3.2px;
                    }
                }
            }
            .card-wrapper {
                .card {
                    color: #78572C;
                    .card-icon {
                        svg {
                            path {
                                fill: #78572C
                            }
                        }
                    }
                }
                .card:last-child p {
                    width: 215px;
                }
            }
            .carouselExtras {
                .carousel-indicators {
                    button {
                        background-color: #1B2540;
                        opacity: 0.2;
                    }
    
                    button.active {
                        opacity: 1;
                    }
                }
                color: #78572C;
                svg {
                    path {
                        fill: #78572C
                    }
                }
            }
        }
    }
    
    .sorrento-exclusive {
        .exclusives {
            background-color: #F7F4F0;
            h2, .exclusives-description {
                color: #1B2540;
            }
            h2 {
                @media screen and (max-width: 576px) {
                    font-size: 44px;
                    line-height: 39.6px;
                    letter-spacing: -3.2px;
                }
            }
            .exclusives-description {
                @media screen and (max-width: 576px) {
                    margin-bottom: 64px;
                    font-size: 14px;
                    line-height: 14px;
                }
            }
            @media screen and (max-width: 768px) {
                padding: 60px 0;
            }
            .photo-wrapper {
                background-color: #FBF9F6;
                gap: 0;
                @media screen and (max-width: 576px) {
                    gap: 20px;
                }
                .photo-card-inner {
                    background-color: #FBF9F6;

                    .photo-card-inner-imgBox {
                        @media screen and (max-width: 576px) {
                            width: 100%;
                            height: 500px;
                        }

                        img {
                            @media screen and (max-width: 576px) {
                                height: 100%;
                            }
                        }
                    }

                    .photo-card-inner-textBox {
                        @media screen and (max-width: 576px) {
                            width: 100%;
                            padding: 40px 20px;
                        }
                    }

                    .photo-card-inner-textBox-title {
                        color: #78572C;
                        font-family: "Montserrat";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: 20px;
                        letter-spacing: 0.4px;
                        text-transform: uppercase;
                    }

                    .photo-card-inner-textBox-text {
                        color: #78572C;
                        font-family: "Montserrat";
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px;
                        letter-spacing: -0.2px;
                        text-transform: capitalize;
                    }
                }
            }
        }
    }

    .amenities {
        .amenities-list {
            margin-bottom: 26px;
        }
        @media screen and (max-width: 768px) {
            padding: 0px 20px 60px 20px !important;

            .amenities-title {
                font-size: 44px;
                line-height: 39.6px;
                letter-spacing: -3.2px;
            }

            .amenities-list-title {
                font-size: 20px;
            }

            .amenities-list {
                flex-wrap: wrap;
                gap: 8px;

                li {
                    width: fit-content;
                    margin-bottom: 16px;
                    margin-right: 12px;
                }

                .amenities-list-item-text {
                    font-size: 20px;
                    font-family: "Montserrat";
                    line-height: 24px;
                    letter-spacing: -0.2px;
                }
            }
        }
    }
}