.top-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;

    .back-btn {
        margin-right: 1em;
    }
}

.main-area.pricing {
    background-color: #181a26;
    border: 2px solid var(--bs-primary);
    border-radius: 24px;
    display: block;
    overflow: auto;
    padding: 2em;
    height: calc(100vh - 200px);

    .list-group {
        max-height: calc(100vh - 300px);
        overflow-y: auto;
    }

    .list-group-item {
        background-color: transparent;
        border: none;
        border: 1px solid var(--bs-primary);
        color: #fff;
    }

    .fc .fc-daygrid-day.fc-day-today {
        background-color: rgba(var(--bs-primary), 0.5);
        border: 2px solid var(--bs-primary);
    }

    .fc .fc-daygrid-day-frame {
        height: 30px;
    }

    .fc .fc-scrollgrid-liquid {
        height: calc(100vh - 320px);
    }
}

.form-switch {
    .form-check-input {
        background-color: rgba(255,255,255,0.1);
        height: 30px;
        width: 60px !important;
        border: 1px solid rgba(255,255,255,0.5);
        margin-top: 0px;
    }
}

.fc-h-event {
    background-color: var(--bs-primary);
    border: 1px solid var(--bs-primary);
    display: block;
}

::-webkit-calendar-picker-indicator {
    filter: invert(1);
}