.desktop-menu {
    width: 100%;
    position: absolute;
    top: 160px;
    z-index: 8;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 60px;

    &-left {
      display: flex;
      align-items: center;
      gap: 24px;

      a {
        color: #FFFFFF;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        line-height: 20.3px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    &-right {
      display: flex;
      align-items: center;
      gap: 24px;

      .name-icon-container {
        span {
          color: #FFF;
        }
      }

      a.phone-text {
        color: #FFF;
        font-family: Montserrat;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px; 
      }

      a.book-now {
        display: flex;
        height: 50px;
        padding: 19px 50px;
        justify-content: center;
        align-items: center;
        background: rgba(248, 246, 244, 0.20);
        color: #FFF;
        text-align: center;
        font-family: Montserrat;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
      }
    }
}