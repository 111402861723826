.offcanvas-backdrop {
    background-color: #F7F4F0 !important;
}

.offcanvas {
    width: 100% !important;
    background-color: #F7F4F0 !important;
    border-right: none !important;

    img {
        max-height: 60px;
        width: auto;
        margin-left: 16px;
    }

    .offcanvas-body {
        padding: 24px 16px !important;
        flex-grow: unset;
        a.menu-item {
            padding: 12px 0;
            font-family: 'Montserrat';
            font-size: 18px;
            font-weight: 500;
            text-align: left;
            line-height: 28.8px;
            display: block;
            width: 100%;
            color: #1B2540;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        a.phone-text {
            padding: 24px 0;
            color: #000;
            font-family: Montserrat;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 32px;
            text-transform: uppercase;
            text-align: left;
            margin-bottom: 0;
        }

        a.book-now {
            background-color: #1B2540;
            padding: 13px 20px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: #FFF;
            font-family: Montserrat;
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 20.3px;
            letter-spacing: 1.12px;
            text-transform: uppercase;
            height: 52px;
            width: fit-content;
        }
    }
}

.offcanvas-header {
    padding: 24px 16px !important;
    .btn-close {
        width: 30px;
        height: 30px;
        opacity: 1 !important;
        background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%72437'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    }
}

.offcanvas-footer {
    margin-top: auto;
    text-align: center;
    padding-bottom: 94px;
    > div {
        gap: 38px;
        display: flex;
        justify-content: center;
    }
     a{
        svg {
            width: 42px;
            height: 42px;
            margin: 0;
        }
    }
}

@media (max-width: 576px) {
    .offcanvas {    
        img {
            max-height: 48px;
            width: auto;
        }
    }
}