.modal-header {
    background-color: #FFFFFF;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;

    .modal-title {
        color: #000000;
        font-family: 'Cormorant Garamond';
        font-size: 36px;
        line-height: 43.2px;
        letter-spacing: -0.72px;

        @media only screen and (max-width: 768px) {
            font-size: 28px;
            line-height: 33.6px;   
        }
    }

    .close-btn {
        height: 24px;
        width: 24px;
        color: #000000;
        cursor: pointer;

        svg {
            height: 100%;
            width: 100%;
        }
    }
}

.modal-body {
    .logout-question {
        margin-top: 24px;
        color: #676767;
        font-family: 'Montserrat';
        font-size: 18px;
        line-height: 28.8px;

        @media only screen and (max-width: 768px) {
            font-size: 16px;
            line-height: 24px;
            margin-top: 16px;
        }
    }

    .logout-buttons {
        display: flex;
        align-items: center;
        gap: 32px;
        margin-top: 44px;

        @media only screen and (max-width: 768px) {
            flex-direction: column;
            gap: 16px;
            margin-top: 32px;
        }

        .logout-button {
            padding: 22px 50px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex: 1;
            font-family: 'Montserrat';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.12px;
            text-transform: uppercase;
            border: none;

            @media only screen and (max-width: 768px) {
                width: 100%;
                padding: 16px 20px;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 20.3px;
            }
        }

        .logout-button.logout-cancel {
            background-color: rgba(27, 37, 64, 0.20);
            backdrop-filter: blur(9.5px);
            color: #1B2540;
        }

        .logout-button.logout-action {
            background-color: #BA1A1A;
            color: #EDEBE7;
        }
    }
}