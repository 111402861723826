.dateRange.fullScreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
    z-index: 9999;

    .dateRangeHeader {
        padding: 16px 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);

        .dateRangeHeaderTitle {
            font-size: 28px;
            line-height: 33.6px;
            color: #000000;
            font-family: 'Cormorant Garamond';
        }

        .closeButton {
            svg {
                color: #000000;
                width: 24px;
            }
        }
    }

    .rdrDateRangeWrapper {
        width: 100%;

        .rdrMonth {
            margin: auto;
            padding: 0;
            .rdrWeekDays, .rdrDays {
                justify-content: center;
            }
        }
    }
}

.rdrDateDisplayWrapper {
    display: none;
}

.rdrDateRangeWrapper {
    position: absolute;
    z-index: 50;
}

.rdrCalendarWrapper {
    background-color: #FFFFFF;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
}

.rdrWeekDays .rdrWeekDay {
    width: 40px;
    flex-basis: auto;
    color: #676767;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 22.4px;
}

.rdrDayDisabled {
    background-color: #FFFFFF !important;
    span.rdrDayNumber {
        span.rdrDayAfterNumber {
            color: rgba(0, 0, 0, 0.3)!important;
            text-decoration: line-through;
        }
        span.rdrBeforeDayNumber {
            color: rgba(0, 0, 0, 0.2)!important;
        }
    }
    cursor: default !important;
}

.rdrDay {
    height: 40px !important;
    width: 40px !important;
    text-align: center;
}

.rdrDayNumber {
    span {
        font-size: 14px;
        font-weight: 400;
        font-family: 'Montserrat';
        color: #000000 !important;
    }
}

.rdrDayStartPreview, .rdrDayEndPreview, .rdrDayInPreview {
    border: none !important;
    background: transparent !important;
}

.rdrStartEdge + span.rdrDayNumber,
.rdrStartEdge ~ .rdrDayNumber {
    span {
        color: #FFFFFF !important
    }
}

.rdrEndEdge + span.rdrDayNumber,
.rdrEndEdge ~ .rdrDayNumber {
    span {
        color: #FFFFFF !important
    }
}

.rdrDayToday {
    border: 2px solid #9FB0BA !important
}

.rdrDayToday .rdrDayNumber span:after {
    display: none;
}

.rdrDayStartOfMonth .rdrInRange, .rdrDayStartOfMonth .rdrEndEdge, .rdrDayStartOfWeek .rdrInRange, .rdrDayStartOfWeek .rdrEndEdge {
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
    left: 0 !important;
}
.rdrDayEndOfMonth .rdrInRange, .rdrDayEndOfMonth .rdrStartEdge, .rdrDayEndOfWeek .rdrInRange, .rdrDayEndOfWeek .rdrStartEdge {
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
}
.rdrDayHovered {
    span {
        border: none;
        border-color: none;
        background: none;
        color: #000000 !important;
    }

    background-color: #F3F5FB !important;
}

.rdrMonthAndYearPickers {
    display: none !important;
    span {
        display: unset !important;
        padding: 0 !important;
    }

    select {
        color: #181A1B !important;
        font-weight: 500 !important;
        font-family: 'Montserrat'
    }
}

.rdrMonths.rdrMonthsHorizontal {
    margin-bottom: 30px;

    @media(max-width: 768px) {
        margin-bottom: 0;
        flex-direction: column;
        gap: 30px;

        .rdrMonth {
            width: 100%;

            .rdrWeekDays {
                justify-content: space-between !important;

                .rdrWeekDay {
                    flex: 0 0 calc(100% / 7);
                    box-sizing: border-box;
                }
            }

            .rdrDays {
                justify-content: space-between !important;

                .rdrDay {
                    flex: 0 0 calc(100% / 7);
                    box-sizing: border-box;
                }
            }
        }
    }
}

.rdrMonths .rdrMonth:first-child {
    @media(max-width: 768px){ 
        position: relative;
    }
}

.rdrMonths .rdrMonth:first-child .rdrMonthName {
    color: #181A1B;
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 22.4px;
    position: absolute;
    left: 100px;
    top: 13px;

    @media(max-width: 768px) {
        top: -47px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.rdrMonths .rdrMonth:nth-child(2) {
    @media(max-width: 768px){ 
        margin-top: 40px !important;
        position: relative;
    }
}

.rdrMonths .rdrMonth:nth-child(2) .rdrMonthName {
    color: #181A1B;
    font-weight: 500;
    font-family: "Montserrat";
    font-size: 14px;
    line-height: 22.4px;
    position: absolute;
    right: 115px;
    top: 13px;

    @media(max-width: 768px){ 
        top: -50px;
        left: 50%;
        transform: translateX(-50%);
        width: max-content;
    }
}

.rdrNextPrevButton {
    background: #FFFFFF !important;
    width: 15px;
    height: 15px;
    border-radius: 0 !important;

    &:hover {
        border-radius: 100% !important;
    }
}

.rdrInRange {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0;
    background-color: #F3F5FB !important;
}

.rdrStartEdge {
    width: 100%;
    height: 100%;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    right: 0;
    border-top-left-radius: unset !important;
    border-bottom-left-radius: unset !important;
    left: unset !important;
    background-color: #1B2540 !important;
    border: none !important
}

.rdrEndEdge {
    width: 100%;
    height: 100%;
    position: absolute !important;
    left: 0 !important;
    top: 0 !important;
    border-top-right-radius: unset !important;
    border-bottom-right-radius: unset !important;
    right: unset !important;
    border-bottom-right-radius: unset !important;
    background-color: #1B2540 !important;
    border: none !important
}

@media(max-width: 999px){
    .jso-popup.jso-popup-fullscreen.jso-quantity-selector-popup{
        height: 100% !important;
    }
}

@media(max-width: 390){
    .rdrWeekDays{
        width: 300px !important;
    }
    .rdrMonth{
        padding: 0 !important;
        width: 300px !important;
    }
}

.listing-page-dat-picker-footer {
    position: absolute;
    top: 415px;
    font-size: 14px;
    line-height: 22.4x;
    padding-left: 10px;
    display: flex;
    justify-content: space-between;
    gap: 10px;
    background-color: #FFFFFF;
    font-family: 'Montserrat';

    .date-texts {
        color: #676767;
    }

    .nights-count {
        color: #000000;
    }
}