/**
 * Dropdown Styles
 */
.jso-dropdown{
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 10000;

    display: flex;

    width: 100%;
    box-shadow: 0 2px 16px rgba(0,0,0,.15);
    box-sizing: border-box;
    border-radius: 4px;

    font-family: -apple-system,system-ui,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,sans-serif;
    font-size: .9rem;

    background: #fff;
    color: #212529;
}

/**
 * fade animation
 */
.jso-dropdown.jso-fade{
    animation: jso-dd-fade-in 0.3s forwards;
}

.jso-dropdown.jso-fade.jso-animation-end{
    animation: jso-dd-fade-out 0.3s forwards;
}

@keyframes jso-dd-fade-in {
    0%   { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes jso-dd-fade-out {
    0%   { opacity: 1; }
    100% { opacity: 0; }
}

/**
 * slide animation
 */
.jso-dropdown.jso-slide{
    animation: jso-dd-slide-down 0.3s forwards;
    transform-origin: top center;
}

.jso-dropdown.jso-slide.jso-animation-end{
    animation: jso-dd-slide-up 0.3s forwards;
    overflow: hidden;
}

@keyframes jso-dd-slide-down {
    /*0% { transform: scaleY(0); }
    100% { transform: scaleY(1); }*/

    0% { max-height: 0; }
    100% { max-height: 500px; }
}

@keyframes jso-dd-slide-up {
    /*0% { transform: scaleY(1); }
    100% { transform: scaleY(0); }*/

    0% { max-height: 500px; }
    100% { max-height: 0; }
}

/**
 * fade + slide
 */
.jso-dropdown.jso-fade-slide{
    animation: jso-dd-fade-in-slide-down 0.3s forwards;
    transform-origin: top center;
}

.jso-dropdown.jso-fade-slide.jso-animation-end{
    animation: jso-dd-fade-out-slide-up 0.3s forwards;
    overflow: hidden;
}

@keyframes jso-dd-fade-in-slide-down {
    0%   { opacity: 0; max-height: 0; }
    100% { opacity: 1; max-height: 500px; }
}

@keyframes jso-dd-fade-out-slide-up {
    0%   { opacity: 1; max-height: 500px; }
    100% { opacity: 0; max-height: 0; }
}

/**
 * shape animation
 */
.jso-dropdown.jso-shape{
    animation: jso-dd-shape-in 0.3s forwards;
    overflow: hidden;
}

.jso-dropdown.jso-shape.jso-animation-end{
    animation: jso-dd-shape-out 0.3s forwards;
}

@keyframes jso-dd-shape-in {
    0%   {
        border-radius: 0.5rem 0.5rem 100% 100%;
        max-height: 0;
    }

    100% {
        border-radius: 0.5rem;
        max-height: 500px;
    }
}

@keyframes jso-dd-shape-out {
    0%   {
        border-radius: 0.5rem;
        max-height: 500px;
    }

    100% {
        border-radius: 0.5rem 0.5rem 100% 100%;
        max-height: 0;
    }
}

/**
 * slide left + right animation
 */
.jso-dropdown.jso-slide-right{
    animation: jso-dd-hslide-in 0.3s forwards;
    transform-origin: top left;
}

.jso-dropdown.jso-slide-right.jso-animation-end{
    animation: jso-dd-hslide-out 0.3s forwards;
    transform-origin: top right;
}

.jso-dropdown.jso-slide-left{
    animation: jso-dd-hslide-in 0.3s forwards;
    transform-origin: top right;
}

.jso-dropdown.jso-slide-left.jso-animation-end{
    animation: jso-dd-hslide-out 0.3s forwards;
    transform-origin: top left;
}

@keyframes jso-dd-hslide-in {
    0% {
        transform: rotateY(90deg);
    }

    100% {
        transform: rotateY(0);
    }
}

@keyframes jso-dd-hslide-out {
    0%   {
        transform: rotateY(0);
    }

    100% {
        transform: rotateY(90deg);
    }
}