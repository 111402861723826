@import '../../App.scss';

.new-header {
  display: flex;
  background-color: white;
  padding: 43px 64px;
  font-family: Montserrat, sans-serif;
}

.new-header * {
  color: $base-blue;
}

.header-item {
  display: flex;
  flex: 1;
  align-items: center;
}

.header-navbar-item {
  padding: 13px 20px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 14px;
  white-space: nowrap;
  font-family: 'Montserrat';

  @media only screen and (min-width: 1260px) and (max-width: 1570px) {
    padding: 7px;
  }
}

.header-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-grow: 0;
}

.header-user-section-container {
  justify-content: flex-end;
  gap: 40px;
}

.phone-text {
  padding-left: 5px;
}

.name-icon-container {
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-family: 'Montserrat';
    font-size: 16px;
    line-height: 16px;
    color: #1B2540
  }
}

.header-book-now-btn {
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  letter-spacing: 0.24px;
  background: #1B2540;
  padding: 19px 50px;

  span {
    color: #FFFFFF;
  }
}

.mobile-menu {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100%;
  left: 0;
  background-color: #F3F5FB;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  background-color: #f3f5fb;
  *{
      color: $base-blue;
  }

  .mobile-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 20px;
    border-bottom: 1px solid #9FB0BA;

    .logo {
      height: 32px;
      width: 80px;

      img {
        height: 100%;
        width: 100%;
      }
    }
  }
}

.mobile-navbar {
  display: flex;
  flex-direction: column;
  padding: 0 16px 16px 16px;
  margin-top: 24px;
}

.menuToggle {
  all: unset;
  display: none;
}

.menuToggle span {
  display: block;
  width: 25px;
  height: 2px;
  margin-bottom: 5px;
  position: relative;

  background: $base-blue;
  border-radius: 3px;
}

.menuToggle span:first-child {
  transform-origin: 0% 0%;
}

.menuToggle span:nth-last-child(2) {
  transform-origin: 0% 100%;
}

.mobile-navbar-item {
  padding: 12px 0;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'Montserrat';
  font-weight: 500;
  line-height: 28.8px;
}

.mobile-navbar-item.phone-number {
  color: #000000;
}

.mobile-navbar-item.mobile-book-btn{
    background-color: $base-blue;
    color: white;
    padding: 13px 20px;
    font-size: 14px;
    letter-spacing: 1.12px;
    line-height: 23px;
    width: fit-content;
}

html.no-scroll {
  overflow: hidden;
}

@media only screen and (max-width: 1260px) {
  .menuToggle {
    display: block;
  }

  .new-header {
    padding: 16px 20px;
  }

  .header-navbar,
  .header-user-section-container {
    display: none;
  }

  .header-logo-container {
    justify-content: flex-start;
    flex-grow: 1;
  }
}

@media only screen and (max-width: 1540px) {
  .new-header {
    align-items: baseline;
  }
  .header-navbar {
    flex-wrap: wrap;
  }
}
