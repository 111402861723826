.multiselect {
    width: 100%;

    button.dropdown-toggle {
        width: 100%;
        min-height: 60px;
        white-space: break-spaces;
        border: 1px solid rgba(255, 255, 255, 0.5);
        border-radius: 10px;
        background-color: rgba(239, 240, 247, 0.05);
        min-height: 60px;

        &:hover, &:focus, &:active {
            color: white;
            background-color: rgba(239, 240, 247, 0.05);
        }
    }

    .dropdown-menu {
        background-color: #181a26;

        .dropdown-item {

            label {
                color: #acb2c1;
                opacity: 1;
            }

            input[type="checkbox"] {
                border-color: #acb2c1;
            }

            &:hover {
                background-color: #4a4e67;
            }

            &.text-white {
                label {
                    color: white !important;
                }
            }
        }
    }

    &.empty {
        button.dropdown-toggle {
            font-size: 14px;
            color: rgba(255, 255, 255, 0.4);
        }
    }
    .bg-primary{
        background-color: #1B2540 !important;
    }
}