/**
 * calendar Styles
 */
:root {
    --jso-calendar-bg:             #fff;
    --jso-calendar-hover-bg-color: #e8e8e8;
    --jso-calendar-past-day-color: #ccc;
    --jso-calendar-today-color:    #007bff;
}

.jso-calendar-month {
    width: 100%;
}

.jso-calendar-month-title {
    cursor:      pointer;
    font-weight: 600;
    padding:     0.4rem;
    position:    relative;
    text-align:  center;
}

.jso-calendar-month-arrow {
    align-items:     center;
    background:      var(--jso-calendar-bg, #fff);

    border:          0;
    cursor:          pointer;
    display:         flex;
    height:          1.7rem;

    justify-content: center;
    position:        absolute;
    top:             50%;
    transform:       translateY(-50%);
    transition:      0.3s all;
    width:           1.7rem;
}

.jso-calendar-month-arrow:hover,
.jso-calendar-month-arrow:focus,
.jso-calendar-month-arrow:active {
    background:    #efefef;
    border-radius: 100%;
    box-shadow:    none;
    outline:       none;
}

.jso-calendar-month-arrow-left {
    left: 0;
}

.jso-calendar-month-arrow-right {
    right: 0;
}

.jso-calendar-day-of-week-title {
    font-weight: normal;
    padding:     0.4rem;
    text-align:  center;
}

.jso-calendar-day {
    border:     1px solid rgba(128, 128, 128, 0.5);
    padding:    0.4rem;
    text-align: center;
}

.jso-calendar-today {
    color:  var(--jso-calendar-today-color, #007bff);
    cursor: pointer;
}

.jso-calendar-after-today {
    cursor: pointer;
}

.jso-calendar-before-today {
    color: var(--jso-calendar-past-day-color, #ccc);
}

.jso-calendar-today:hover,
.jso-calendar-after-today:hover {
    background: var(--jso-calendar-hover-bg-color, #e8e8e8);
}
