/* Variables */

$primary:                     #ee4b81;

$body-bg:                     #1D1F2D;
$body-color:                  #DDDDDD;
$font-family-base:            Poppins, sans-serif;
$font-weight-base:            400;
$line-height-base:            1.8;
$form-select-indicator-color: white;
$link-decoration:             none;

$container-max-widths:        (sm: 540px, md: 720px, lg: 960px, xl: 1140px, xxl: 1440px, xxxl: 1643px);

$accordion-border-color:      #454545;
$accordion-bg:                transparent;
$accordion-button-active-bg:  transparent;

$offcanvas-bg-color:          $body-bg;

$base-border-color: #9FB0BA;
$base-blue: #1B2540;
$base-light-blue: #9FB0BA;
:root {
    --bs-primary: $primary;
}

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600;1,700&display=swap');
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import "../node_modules/react-image-gallery/styles/scss/image-gallery.scss";
@import '../node_modules/bootstrap/scss/bootstrap';
@import "assets/styles/rebootstrap";
@import "assets/styles/global";
