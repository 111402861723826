.listing-card-skeleton {
    .title-skeleton {
        margin-top: 32px;
    }

    .info-skeletons {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 24px;
        margin-top: 16px;
    }

    .footer-skeletons {
        margin-top: 32px;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;

        @media only screen and (max-width: 1000px) {
            align-items: center;
        }

        .prices-skeletons {
            display: flex;
            align-items: center;
            gap: 10px;

            @media only screen and (max-width: 1000px) {
                flex-direction: column;
            }
        }
    }
}