/**
 * Popup Styles
 */
.jso-popup{
    display: flex;
    width: 100%;
    box-sizing: border-box;

    font-family: -apple-system,system-ui,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,sans-serif;
    font-size: 0.9rem;

    background: #fff;
    color: rgb(33, 37, 41);
}

.jso-popup-fullscreen{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 10000;
    background-color: #fff;
}

.jso-popup-box{
    width: 100%;
    height: 100%;
    box-sizing: border-box;

    display: flex;
    flex-direction: column;

    background: #fff;
}

.jso-popup-content-top{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.3rem 1rem 0.3rem 0;
    position: relative;
    box-sizing: border-box;
}

.jso-popup-content-top h4{
    font-weight: bold;
    font-size: 1rem;
    padding: 0 0 0 30px;
    margin: 0;
    flex: 1;
    text-align: center;
    box-sizing: border-box;
}

.jso-popup-close-button{
    background: #fff url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 100 100' width='30px' height='30px'%3E%3Cpolygon fill='%23010101' points='77.6,21.1 49.6,49.2 21.5,21.1 19.6,23 47.6,51.1 19.6,79.2 21.5,81.1 49.6,53 77.6,81.1 79.6,79.2 51.5,51.1 79.6,23 '/%3E%3C/svg%3E") no-repeat left center;
    border: 0;
    width: 30px;
    height: 30px;
}

.jso-popup-close-button:focus,
.jso-popup-close-button:hover,
.jso-popup-close-button:active{
    outline: none;
    box-shadow: none;
}