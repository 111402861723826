.reservations-not-found-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #FFFFFF;
    padding: 32px;

    @media screen and (max-width: 768px) {
        margin-bottom: 72px;
    }

    @media screen and (max-width: 1200px) {
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        padding: 16px;
    }

    .reservations-button-wrapper {
        @media screen and (max-width: 1200px) {
            width: 100%;
        }

        .header-book-now-btn {
            color: #1B2540;
            text-align: center;
            font-family: Montserrat;
            font-size: 12px;
            font-weight: 500;
            line-height: 12px;
            letter-spacing: 0.24px;
            text-transform: uppercase;
    
            @media screen and (max-width: 1200px) {
                width: 100%;
            }
        }
    }

    .reservations-not-found {
        display: flex;
        align-items: center;
        gap: 24px;
    
        .icon-container {
            background-color: #F3F5FB;
            border-radius: 50%;
            padding: 20px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    
        .text-container {
            display: flex;
            flex-direction: column;
            gap: 12px;
    
            .main-text {
                font-size: 18px;
                line-height: 28.8px;
                font-family: 'Montserrat';
                font-weight: 400;
                color: #000;
                margin-bottom: 0;
            }
        }
    }
}