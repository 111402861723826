.create-listing-form {
    background-color: #181a26;
    border: 2px solid var(--bs-primary);
    border-radius: 24px;
    display: block;
    overflow: auto;
    padding: 2em;

    fieldset {
        border-bottom: 1px solid var(--bs-primary);
        margin-bottom: 2em;
        padding-bottom: 1em;

        >div.row {
            > div {
                margin-bottom: 1em;
            }
        }

        select {
            height: 60px;
            border-radius: 10px;
            border: 1px solid rgba(255, 255, 255, 0.5);
        }

        .pictures-preview {
            display: flex;
            flex-wrap: wrap;
            justify-content: start;
            margin-bottom: 1em;

            .picture-preview {
                height: 300px;
                margin-bottom: 1em;
                border-radius: 10px;
                border: 1px solid var(--bs-primary);
                background-size: cover;
                background-position: center;
                background-repeat: no-repeat;
                padding: 1em;
                overflow: auto;

                img {
                    width: 100%;
                }
            }
        }

        input[required], select[required], textarea[required] {
            background-image: url('data:image/svg+xml;charset=utf-8,<svg style="color: red" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><rect width="256" height="256" fill="none"></rect><path d="M208.2,165.1,144,128l64.2-37.1a8,8,0,1,0-8-13.8l-64.2,37V40a8,8,0,0,0-16,0v74.1l-64.2-37a8,8,0,0,0-8,13.8L112,128,47.8,165.1A8,8,0,0,0,44.9,176a7.7,7.7,0,0,0,6.9,4,7.3,7.3,0,0,0,4-1.1l64.2-37V216a8,8,0,0,0,16,0V141.9l64.2,37a7.3,7.3,0,0,0,4,1.1,7.7,7.7,0,0,0,6.9-4A8,8,0,0,0,208.2,165.1Z" fill="red"></path></svg>');
            background-repeat: no-repeat;
            background-size: 16px;
            background-position: right 6px top 6px;
        }

    }

    .invalid-feedback {
        display: initial;
    }

    ::-webkit-calendar-picker-indicator {
        filter: invert(1);
    }
    ::-webkit-time-picker-indicator {
        filter: invert(1);
    }
}

.mb-1em {
    margin-bottom: 1em;
}

.submit-area {
    display: flex;
    flex-direction: column;
    align-items: end;
}