.details-modal {
    padding-right: 0 !important;
    .modal-dialog {
        max-width: 100%;
    }
    .modal-content {
        background-color: #FFFFFF;
        border-radius: initial;
        padding: 36px;
        max-width: 786px;
        width: 80%;
        margin: auto;

        @media only screen and (max-width: 1200px) {
            width: 95%;
        }

        .modal-header {
            background-color: #FFFFFF;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0;

            .modal-title {
                color: #000000;
                font-family: 'Cormorant Garamond';
                font-size: 36px;
                line-height: 43.2px;
                letter-spacing: -2%;
            }

            .close-btn {
                height: 24px;
                width: 24px;
                color: #000000;
                cursor: pointer;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
        }

        .modal-body {
            background-color: #FFFFFF;
            padding: 0;

            .image-name-status-button {
                margin-top: 32px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 32px;

                @media only screen and (max-width: 1200px) {
                    flex-direction: column;
                }

                .image-name-status {
                    display: flex;
                    align-items: center;
                    gap: 32px;

                    @media only screen and (max-width: 1200px) {
                        flex-direction: column;
                        width: 100%;
                    }
                }

                .image {
                    height: 120px;
                    width: 190px;

                    @media only screen and (max-width: 1200px) {
                        width: 100%;
                        height: 160px;
                    }

                    img {
                        height: 100%;
                        width: 100%;
                    }
                }

                .name-status {
                    display: flex;
                    flex-direction: column;

                    @media only screen and (max-width: 1200px) {
                        flex-direction: column;
                        gap: 8px;
                        width: 100%;
                    }

                    .status-mobile {
                        @media only screen and (max-width: 1200px) {
                            display: flex;
                            justify-content: space-between;
                        }
                    }

                    .status {
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 22.4px;
                        color: #676767;
                        margin-bottom: 0;
                    }

                    .name {
                        font-family: 'Montserrat';
                        font-weight: 500;
                        font-size: 18px;
                        line-height: 28.8px;
                        color: #1B2540;
                        margin-bottom: 0;
                    }
                }

                .more-btn {
                    cursor: pointer;
                }

                @media only screen and (max-width: 1200px) {
                    .more-btn.more-btn-desktop {
                        display: none;
                    }
                }

                @media only screen and (min-width: 1200px) {
                    .more-btn.more-btn-mobile {
                        display: none;
                    }
                }

                .more-dropdown-menu {
                    position: absolute;
                    right: 0;
                    top: 130px;
                    background: #FFF;
                    box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);

                    @media only screen and (max-width: 1200px) {
                        top: 265px
                    }

                    .menu-item {
                        padding: 20px;
                        font-family: 'Montserrat';
                        font-size: 14px;
                        font-weight: 500;
                        line-height: 20.3px;
                        letter-spacing: 1.12px;
                        text-transform: uppercase;
                        color: #000000;
                        cursor: pointer;
                    }

                    .menu-item.danger {
                        color: #BA1A1A;
                    }
                }
            }

            .reservation-details {
                margin-top: 32px;
                padding: 0;
                gap: 0;

                .details-title {
                    color: #1A2028;
                    margin-bottom: 24px;
                    font-family: 'Montserrat';
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 23.2px;
                    letter-spacing: 1.28px;
                    text-transform: uppercase;
                }

                .details-table {
                    padding-left: 24px;
                    padding-right: 24px;
                    background-color: #F3F5FB;

                    @media only screen and (max-width: 1200px) {
                        padding-top: 16px;
                        padding-bottom: 16px;
                    }

                    .row.last {
                        border-bottom: none;
                    }

                    .row {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        padding-bottom: 20px;
                        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
                        margin: 0;
                        padding-top: 24px;
                        padding-bottom: 24px;

                        @media only screen and (max-width: 1200px) {
                            flex-direction: column;
                            gap: 4px;
                            padding-top: 16px;
                            padding-bottom: 16px;
                        }

                        .key {
                            flex: 1;
                            color: #1A2028;
                            font-family: 'Montserrat';
                            font-size: 16px;
                            line-height: 25.6px;
                            margin-bottom: 0;
                            padding: 0;
                        }

                        .value {
                            flex: 1;
                            color: #1A2028;
                            font-family: 'Montserrat';
                            font-size: 16px;
                            font-weight: 500;
                            line-height: 25.6px;
                            margin-bottom: 0;
                            padding: 0;
                        }
                    }
                }
            }
        }
    }
}