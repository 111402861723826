@import '../../App.scss';

.header {
    margin:   auto;
    padding:  16px 0;
    position: relative;
    z-index:  1;

    div {
        align-items: center;
        display:     flex;
        padding:     15px;
        text-align:  center;

        :nth-child(2) {
            justify-content: end;
        }

    }

    .logo {
        max-height: 60px;
        width:      auto;
    }

    a {
        color:     white;
        font-size: 16px;

        img {
            width: 40px;
        }
    }

    svg {
        font-size: 32px;
    }

    @media screen and (max-width: 768px) {
        .header-logo-column {
            scale: .8;
            padding-left: 0;
        }

        .header-menu-column {
            scale: .8;
            padding-right: 0;
        }
    }

    @media screen and (max-width: 1280px) {
        .header-menu-column {
            max-width: 280px;
        }
    }

}

.offcanvas {
    width: 100% !important;

    img {
        max-height: 60px;
        width: auto;
    }

    .offcanvas-body {
        padding-top: 83px;
        flex-grow: unset;
        > a {
            font-size: 18px;
            text-align: center;
            line-height: 18px;
            display: block;
            margin-bottom: 46px;
            width: 100%;
            color: white;
        }
        > a.active {
            color: $primary;
        }
    }
}

.offcanvas-header {
    padding: 15px;
    .btn-close {
        width: 30px;
        height: 30px;
        opacity: 1 !important;
    }
}

.offcanvas-footer {
    margin-top: auto;
    text-align: center;
    padding-bottom: 94px;
    > div {
        gap: 38px;
        display: flex;
        justify-content: center;
    }
     a{
        svg {
            width: 42px;
            height: 42px;
            margin: 0;
        }
    }
}

@media (max-width: 576px) {
    .offcanvas {    
        img {
            max-height: 48px;
            width: auto;
        }
    }
}