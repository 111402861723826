/**
 * Autocomplete CSS
 */

/**
 * desktop: dropdown
 */
.jso-autocomplete-content{
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: #fff;
    padding: 0.5rem 0;
}

.jso-autocomplete-item{
    padding: 0 1rem 0.1rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.jso-autocomplete-item:hover,
.jso-autocomplete-item:active,
.jso-autocomplete-item:focus,
.jso-autocomplete-selected-item{
    background: #f9f9f9;
    outline: none;
}

.jso-autocomplete-item >img{
    margin: 0 0.5rem 0 0;
    width: 32px;
    height: 32px;
    pointer-events: none;
}

.jso-autocomplete-no-results{
    padding: 0.5rem 1rem;
}

/**
 * highlight
 */
.jso-highlight-text{
    font-weight: bold;
}

/**
 * mobile version
 */
.jso-autocomplete-popup-top{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin: 0.3rem 1rem 0.3rem 0;
}

/**
 * mobile text box
 */
.jso-autocomplete-input{
    display: block !important;
}

.jso-autocomplete-input.jso-autocomplete-input-mobile{
    display: none !important;
}

.jso-autocomplete-popup-input-box{
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    border-top: 4px solid #8a95a0;
    border-bottom: 4px solid #8a95a0;
}

.jso-autocomplete-popup-input-box::before{
    content: '';
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='25' height='25' viewBox='0 0 512 512'%3E%3Cpath fill='%23bfbfbf' d='M102,480H64V57.37l4.69-4.68C72.14,49.23,92.78,32,160,32c37.21,0,78.83,14.71,115.55,27.68C305.12,70.13,333.05,80,352,80c42.83,0,72.72-14.25,73-14.4l23-11.14V313.89l-8.84,4.42C437.71,319,403.19,336,352,336c-24.14,0-54.38-7.14-86.39-14.71C229.63,312.79,192.43,304,160,304c-36.87,0-49.74,5.58-58,9.11Z'%3E%3C/path%3E%3C/svg%3E") no-repeat 50% 50%;
    width: 20px;
    height: 20px;
    margin: 0 1rem;
}

.jso-autocomplete-popup-input-box input[type="text"]{
    border: 0;
    height: 50px;
    flex: 1;
    font-size: 1rem;
}

.jso-autocomplete-popup-input-box input[type="text"]::placeholder{
    font-size: 1.1rem;
}

.jso-autocomplete-popup-input-box input[type="text"]:focus,
.jso-autocomplete-popup-input-box input[type="text"]:active,
.jso-autocomplete-popup-input-box input[type="text"]:hover{
    outline: none;
    box-shadow: none;
}

/**
 * mobile: autocomplete items list
 */
.jso-autocomplete-mobile-content-box{
    overflow: auto;
}

.jso-autocomplete-popup .jso-autocomplete-content{
    padding: 0;
}

.jso-autocomplete-popup .jso-autocomplete-item{
    border-bottom: 1px solid #efefef;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

/**
 * main text boxes in mobile
 */
@media (max-width: 960px){
    .jso-autocomplete-input,
    .jso-autocomplete{
        display: none !important;
    }

    .jso-autocomplete-input.jso-autocomplete-input-mobile{
        display: block !important;
    }

    .jso-autocomplete-opened .jso-autocomplete-input.jso-autocomplete-input-mobile{
        display: none !important;
    }
}
