/**
 * JSOcean Range Date Picker Styles
 */
.jso-date-range-picker-dropdown{
    display: flex;
    flex-direction: column;
    user-select: none;
}

.jso-date-range-picker-dropdown-content{
    width: 100%;
    min-height: 280px;
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    padding: 0.5rem 1rem;cursor: default;
}

.jso-date-range-picker-dropdown .jso-calendar-month{
    width: 49%;
    border-collapse: collapse;
}

.jso-date-range-picker-dropdown .jso-calendar-month:first-child{
    margin-right: 0.5rem;
}

.jso-date-range-picker-dropdown .jso-calendar-month:last-child{
    margin-left: 0.5rem;
}

.jso-date-range-picker-dropdown-footer{
    display: flex;
    justify-content: center;
    border-top: 1px solid #e6e6e6;
}

.jso-date-range-picker-footer-text{
    margin: 0 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jso-date-range-picker-done-btn{
    padding: 12px 32px 12px 32px;
    background: #1B2540;
    color: #fff;
    border: 0;
    font-size: 1rem;
    border-radius: 3px;
    box-sizing: border-box;
    margin: 0.5rem 0.5rem 0.5rem auto;
    cursor: pointer;
}

/**
 * Mobile Version
 */
.jso-date-range-picker-popup .jso-popup-content-top{
    margin-bottom: 0;
}

.jso-date-range-picker-popup-content{
    flex: 1;
    overflow: auto;
}

.jso-date-range-picker-popup-footer{
    border-top: 1px solid #ccc;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem 0.5rem 0.5rem;
    box-sizing: border-box;
}

.jso-date-range-picker-popup-footer .jso-date-range-picker-popup-done-btn{
    padding: 12px 32px 12px 32px;
    background: #1B2540;
    color: #fff;
    border: 0;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    box-sizing: border-box;
}

.jso-date-range-picker-footer-texts{
    display: flex;
    justify-content: center;
}

.jso-date-range-picker-popup-content .jso-calendar-month{
    margin-bottom: 1rem;
}

.jso-date-range-picker-popup-content .jso-calendar-month-title{
    font-weight: 700;
}

.jso-date-range-picker-popup-content .jso-calendar-day-of-week-title{
    background: #f7f7f7;
}



