.cancellation-modal {
    .title-with-icon {
        display: flex;
        align-items: center;
        gap: 16px;
    }

    .cancellation-text {
        margin-top: 24px;
        font-family: 'Montserrat';
        font-size: 18px;
        line-height: 28.8px;
        color: #676767;

        .dark-text {
            color: #000000;
        }
    }

    .cancellation-fee {
        margin-top: 32px;

        .title {
            color: #000000;
            font-family: 'Montserrat';
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
            letter-spacing: 1.12px;
            text-transform: uppercase;
        }

        .fee-details {
            width: 100%;
            background-color: #F3F5FB;
            padding-left: 24px;
            padding-right: 24px;

            @media only screen and (max-width: 1200px) {
                padding-left: 16px;
                padding-right: 16px;
            }

            .detail {
                display: flex;
                border-bottom: 1px solid rgba(159, 176, 186, 1);

                @media only screen and (max-width: 1200px) {
                    flex-direction: column;
                    gap: 4px;
                    padding-top: 16px;
                    padding-bottom: 16px;
                }

                .text {
                    flex: 1;
                    text-align: left;
                    margin: 0;
                    padding-top: 24px;
                    padding-bottom: 24px;

                    @media only screen and (max-width: 1200px) {
                        padding-top: 0;
                        padding-bottom: 0;
                    }
                }

                .single-text {
                    color: #1A2028;
                    font-family: 'Montserrat';
                    font-size: 16px;
                    font-weight: 500;
                    line-height: 25.6px;
                }

                .bold-text {
                    color: #000000;
                    font-family: 'Montserrat';
                    font-size: 20px;
                    font-weight: 500;
                    line-height: 32px;
                }
            }

            .detail.last {
                border: 0;
            }
        }
    }

    .reasons {
        padding-left: 24px;
        padding-right: 24px;
        background-color: #F3F5FB;
        color: #000000;
        font-family: 'Montserrat';
        font-size: 16px;
        line-height: 25.6px;
        display: flex;
        flex-direction: column;

        .radio-option {
            padding-top: 24px;
            padding-bottom: 24px;
            display: flex;
            align-items: center;
            gap: 8px;
            border-bottom: 1px solid rgba(159, 176, 186, 1);

            input {
                width: 28px;
                height: 28px;
            }
        }

        .radio-option.last {
            border-bottom: none;
        }
    }

    .manual-text-container {
        margin-top: 32px;
        label {
            font-family: 'Montserrat';
            font-size: 16px;
            line-height: 25.6px;
            color: #000000
        }

        textarea {
            margin-top: 9px;
            padding: 12px;
            border: 1px solid #9FB0BA;
            width: 100%;
            color: #000000 !important;
            font-size: 16px;
        }
    }

    .submit-cancel-reservation {
        margin-top: 32px;
        width: 100%;
        color: #EDEBE7;
        font-family: 'Montserrat';
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        text-align: center;
        padding: 22px 48px;
        background-color: #1B2540;
        border: 1px solid #9FB0BA;
    }

    .submit-cancel-reservation.disabled {
        opacity: 0.2;
        pointer-events: none;
    }
}