/**
 * JSOcean Quantity Selector Styles
 */

/**
 * main button
 */
.jso-quantity-selector-fields{
    display: flex;
}

/**
 * dropdown
 */
.jso-quantity-selector-dropdown-content{
    display: flex;
    width: 100%;
    flex-direction: column;
    box-sizing: border-box;
    padding: 0.5rem 0;
}

.jso-quantity-selector-field{
    display: flex;
    width: 100%;
    align-items: center;
    padding: 0.5rem 1rem;
    box-sizing: border-box;
}

.jso-quantity-selector-title{
    flex: 1;
}

.jso-quantity-selector-qty-chooser{
    display: flex;
    align-items: center;
}

.jso-quantity-selector-field-btn{
    background: #fff;
    border: 1px solid #ccc;
    padding: 0;
    margin: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
}

.jso-quantity-selector-field-qty{
    width: 40px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jso-quantity-selector-select{
    border: 1px solid #d2d2d2;
    border-radius: 0.2rem;
    padding: 0.4rem 0.5rem;
    min-width: 3rem;
    box-shadow: 0 1px 0 1px rgba(0,0,0,.04);
}

/**
 * dependant fields
 */
.jso-quantity-selector-dependant-fields{
    background: #f7f7f7;
    padding: 0.5rem 0 1rem 0;
}

.jso-quantity-selector-dependant-field-title{
    padding: .5rem 1rem;
}

.jso-quantity-selector-dependant-fields-box{
    display: flex;
    flex-wrap: wrap;
}

.jso-quantity-selector-field.jso-quantity-selector-dependant-field{
    width: auto;
}

/**
 * Mobile Popup
 */
.jso-quantity-selector-popup .jso-popup-content-top{
    margin-bottom: 0;
}

.jso-quantity-selector-popup-content{
    flex: 1;
    overflow: auto;
    font-size: 1rem;
    background: #fff;
}

.jso-quantity-selector-popup-footer{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0.5rem;
    box-sizing: border-box;
}

.jso-quantity-selector-popup-footer .jso-quantity-selector-popup-done-btn{
    padding: 0.7rem 2rem;
    background: #4e4e4e;
    color: #fff;
    border: 0;
    font-size: 1rem;
    border-radius: 3px;
    width: 100%;
    box-sizing: border-box;
}



