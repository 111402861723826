$font-montserrat: 'Montserrat', sans-serif;

.project-navigation-menu {
  padding-bottom: 58px;
  .menu-container {
    padding: 16px 20px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 2.5rem;
    cursor: pointer;
    overflow-x: auto;
    white-space: nowrap;
    background: white;
    position: fixed;
    z-index: 1000;
    width: 100%;

    @media (max-width: 768px) {
      z-index: 9
    }

    @media (min-width: 640px) {
      justify-content: center;
      padding: 16px 0;
    }

    .menu-item {
      font-family: $font-montserrat;
      font-size: 1rem;
      line-height: 1.6;
      color: #181A1B;

      $font-montserrat: 'Montserrat', sans-serif;

      .project-navigation-menu {
        .menu-container {
          padding: 1rem 0;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 2.5rem;
          cursor: pointer;
          overflow-x: auto;
          white-space: nowrap;

          @media (min-width: 640px) {
            justify-content: center;
            padding: 0 1.25rem;
          }

          .menu-item {
            font-family: $font-montserrat;
            font-size: 1rem;
            line-height: 1.6;
            color: #181A1B;
            opacity: 0.5;
            font-weight: 500;
            text-decoration: none;

            &.active {
              opacity: 1;
              font-weight: 600;
            }
          }
        }
      }

      opacity: 0.5;
      font-weight: 500;
      text-decoration: none;
      margin-bottom: 0;

      &.active {
        opacity: 1;
        font-weight: 600;
      }
    }
  }
}
