.page-title {
    color:          #cccccc;
    color:          rgba(255, 255, 255, 0.6) !important;
    font-size:      32px;
    font-weight:    200;
    letter-spacing: 2px;
    margin-bottom:  50px;
    margin-top:     20px;
    text-align:     center;
    text-transform: uppercase;

}

.jso-booking-check-in-out {
    border: unset !important;
}

.jso-booking-check-in-out-content {
    border-bottom-left-radius:  16px;
    border-bottom-right-radius: 16px;
}

.jso-booking-form-guests, .jso-date-range-picker-popup-content .jso-calendar-day-of-week-title {
    background: #FFFFFF !important;
    border:     unset !important;
}

.jso-booking-check-in-out-content, .jso-booking-form-country {
    background: transparent !important;
    border:     unset !important;
}

.jso-booking-form {
    color: unset !important;
}

.jso-booking-form-guests, .jso-booking-check-in-out, .jso-booking-form-title, .jso-booking-form-country-content input, .jso-booking-form-country-content input::placeholder {
    background-color: rgb(24 26 38 / 80%) !important;
    color:            white !important;
}

.jso-booking-form .jso-quantity-selector-popup, .jso-booking-check-in-out-content .jso-date-range-picker-text, .jso-booking-form-guests .jso-quantity-selector-text {
    border-color: #3b3b3b !important;
}

.jso-autocomplete-item:hover, .jso-autocomplete-content, .jso-dropdown {
    background-color: #1B2540 !important;
}

.search_form_wrapper .jso-booking-form-content .jso-booking-form-guests .jso-popup-box {
    background-color: #FFFFFF !important;

    .jso-popup-content-top {
        h4 {
            color: #1B2540;
        }
    }

    .jso-quantity-selector-popup-content {
        background-color: #FFFFFF !important;

        .jso-quantity-selector-title, .jso-quantity-selector-field-qty {
            color: #1B2540
        }
    }

    .jso-quantity-selector-popup-footer {
        button {
            color: #FFFFFF;
            background-color: #1B2540;
        }
    }
}

.jso-date-range-picker-dropdown, .jso-date-range-picker-dropdown-mobile {
    background-color: #FFFFFF !important;
}


.jso-booking-form-country-content .jso-dropdown.hidden {
    display: none;
}

.jso-popup-close-button {
    background-color:   #FFFFFF;
    color: #000000 !important;
    font-weight: 500;
}

.jso-quantity-selector-field-btn {
    background-color: #1B2540 !important;
    border-color:     #1B2540 !important;
    color:            white !important;
}

.jso-date-range-picker-dropdown {
    width: fit-content !important;
}

.jso-popup-box, .jso-dropdown {
    color: #acb2c1 !important;
}

.jso-calendar-month .jso-calendar-month-title {
    color: #181A1B !important;
    font-weight: 500 !important;
    font-family: 'Montserrat';
    font-size: 14px !important;
    line-height: 22.4px;
}

.jso-calendar-month .jso-calendar-week .jso-calendar-day {
    background-color: #FFFFFF !important;
    color: #000000 !important;
    height: 40px !important;
    width: 40px !important;
    border: none;
    font-family: 'Montserrat';
    font-size: 14px;
}

.jso-date-range-picker-dropdown-content .jso-calendar-month .jso-calendar-week .jso-calendar-day:hover {
    background-color: #F3F5FB !important;
}

.jso-date-range-picker-dropdown-content .jso-calendar-month .jso-calendar-week .jso-calendar-day.jso-calendar-empty-day:hover {
    background-color: transparent !important
}

.jso-date-range-picker-dropdown-content .jso-calendar-month .jso-calendar-week .jso-calendar-day.jso-calendar-before-today:hover {
    background-color: transparent !important
}

.jso-date-range-picker-dropdown-content .jso-calendar-month .jso-calendar-week .jso-calendar-day.jso-date-range-picker-selected-day-from:hover {
    background-color: #1B2540 !important;
}

.jso-date-range-picker-dropdown-content .jso-calendar-month .jso-calendar-week .jso-calendar-day.jso-date-range-picker-selected-day-to:hover {
    background-color: #1B2540 !important;
}

.jso-calendar-month 
.jso-calendar-week 
.jso-calendar-day.jso-calendar-before-today {
    color: rgba(0, 0, 0, 0.2) !important;
} 

.jso-calendar-month 
.jso-calendar-week 
.jso-calendar-day.jso-calendar-today {
    border: 2px solid #9FB0BA;
} 

.jso-calendar-month 
.jso-calendar-week 
.jso-date-range-picker-selected-day-to.jso-calendar-day {
    background-color: #1B2540 !important;
    color: #FFFFFF !important;
    border: none !important
}

.jso-calendar-month 
.jso-calendar-week 
.jso-date-range-picker-selected-day-from.jso-calendar-day {
    background-color: #1B2540 !important;
    color: #FFFFFF !important;
    border: none !important
}

.jso-calendar-day-of-week-title {
    color: #676767 !important;
    font-size: 14px;
    font-family: 'Montserrat';
    line-height: 22.4px;
}

.jso-date-range-picker-dropdown-footer, .jso-date-range-picker-popup-footer{
    .jso-date-range-picker-footer-texts {
        .jso-date-range-picker-footer-date-texts {
            display: flex;
            align-items: center;
            gap: 3px;
        }
        .jso-date-range-picker-nights-count {
            color: #000000 !important;
            font-family: 'Montserrat';
            line-height: 22.4px;
        }
        
        .jso-date-range-picker-footer-text {
            font-size: 14px;
            font-family: 'Montserrat';
            line-height: 22.4px;
            color: #676767;
        }

        .jso-date-range-picker-footer-text-from {
            padding-right: 0;
            padding-left: 0;
        }

        .jso-date-range-picker-footer-text-to {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .jso-date-range-picker-done-btn {
        font-family: 'Montserrat';
        font-size: 12px;
        font-weight: 500;
        line-height: 12px;
        letter-spacing: 0.24px;
        text-transform: uppercase;
        padding: 12px 32px;
        margin-right: 0;
    }

    .jso-date-range-picker-popup-done-btn {
        font-family: 'Montserrat';
        text-transform: uppercase;
    }
}

.jso-popup-content-top-mobile {
    justify-content: flex-start !important;
    padding: 16px 20px 16px 20px !important;
    border-bottom: 1px solid #ccc !important;
}

.jso-popup-content-top-mobile h4 {
    text-align: initial;
    font-size: 28px !important;
    font-weight: 400 !important;
    font-family: 'Cormorant Garamond' !important;
    color: #000000;
    padding-left: 0;
}

.jso-date-range-picker-dropdown-footer {
    margin-top: 8px;
    margin-left: 16px;
    margin-right: 16px;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.jso-date-range-picker-footer-text, .jso-date-range-picker-nights-count {
    border: unset !important;
}

.jso-booking-check-in-out-content div:first-child {
    border-right: 1px solid #ddd;
    /*border-right: 1px solid var(--jso-booking-form-border-color, #efefef);*/
}

.jso-calendar-month 
.jso-calendar-week
.jso-date-range-picker-selected-day {
    background-color: #F3F5FB !important;
}

.jso-booking-form .jso-date-range-picker-dropdown-content {
    padding: 0 1rem !important;
    align-items: flex-start;
}

.jso-booking-form.jso-booking-form-horizontal .jso-booking-form-content {
    justify-content: space-between !important;
}

@media only screen and (max-width: 767px) {
    .jso-booking-form.jso-booking-form-horizontal .jso-booking-form-content {
        padding: 16px !important;
    }
}

.jso-booking-check-in-out:active, .jso-booking-check-in-out:focus, .jso-booking-form-guests:active, .jso-booking-form-guests:focus {
    /*box-shadow: 0 0 .2rem rgb(254 88 138 / 20%) !important;*/
    box-shadow: unset !important;
}

.jso-booking-form-search-btn {
    background: var(--bs-primary) !important;
    box-shadow: unset !important;
}

.jso-autocomplete-item img {
    display: none;
}

.jso-booking-form-title {
    border-bottom: 1px solid #3b3b3b !important;
    margin-bottom: 0 !important;
    margin-top:    0 !important;
}

.jso-booking-form.jso-booking-form-colored.jso-booking-form-separator .jso-booking-form-title {
    background: linear-gradient(135deg, #181a26 50%, #1f2231 51%, #181a26) !important;
}


.policy {
    color:          rgba(255, 255, 255, 0.6);
    letter-spacing: 1px;
    line-height: 22.4px;
}


.form-floating {

    > .form-control, > .form-select {

        height:      calc(4.25rem + 10px);
        padding-top: 2rem !important;

    }

    > textarea.form-control {
        padding-top: 2.5rem !important;
    }

    > label {
        letter-spacing: 0.75px;
        padding:        1.5rem .7rem;
    }

}


.alert-primary {
    background-color: #ee4a81;
    border-color:     #ee4a81;
    color:            white;

    * {
        color: white;
    }

    p {
        margin-bottom: 0;
    }

}


.modal {

    .modal-header {
        background-color: #141621;
        border-bottom:    unset;
    }

    .modal-title {
        line-height: 18px;
    }

    .modal-body {
        background-color: #1D1F2D;
    }

    .modal-footer {
        background-color: #141621;
        border-top:       unset;
    }

}

.image-gallery-image {
    border-radius: 12px;
}

.btn-close {
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23FFF'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
}


.amenities {

    list-style: none;
    padding:    0;

    ul{
       
        li {
            border-bottom: 1px solid #454545;
            font-size:     0.85rem;
            padding:       8px 0;
    
            @media only screen and (max-width: 767px) {
                font-size: 0.9rem;
            }
    
            img {
                margin-right: 8px;
                width: 16px;
            }
    
        }
    }


}

.dates {

    .col p:first-child {
        color:         white;
        font-size:     18px;
        margin-bottom: 0;
    }

    .col p:last-child {
        font-size:  18px;
        margin-top: 0;
    }

    p {
        line-height: 24px;
        margin:      0;
    }

    .bed_icon {

        background-image:    url('../../assets/icons/bed.svg');
        background-position: 80% center;
        background-repeat:   no-repeat;

        @media only screen and (max-width: 1000px) {
            background: unset !important;
        }

    }

}


.pricing_table_wrapper {
    font-size: 0.9rem;
    padding:   0;
    width:     100%;

    table {
        color:      #BDBDBD;
        text-align: left;
        width:      100%;
    }

    tr td, th {
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding:       12px 0;
    }

    thead td, td.darker {
        color:       #F2F2F2;
        font-weight: 600;
    }

    .table_wrapper_triangle {
        box-sizing:    border-box;
        line-height:   10px;
        margin-bottom: -10px;
        overflow:      hidden;
        text-align:    center;
        width:         100%;

        :after {
            border-left:  10px solid transparent;
            border-right: 10px solid transparent;
            content:      "";
            display:      inline-block;
            height:       0px;
            line-height:  10px;
            width:        0px;
        }

    }

    table tr.table_wrapper_first {
        font-weight: bolder;
    }

    table tr.table_wrapper_last td:last-child {
        border-top: double;
    }

    table tr td:last-child, table thead td:last-child {
        text-align: right;
    }

}

.coupon-badge {
    background:    #141621 !important;
    border:        2px dashed var(--bs-primary) !important;
    border-radius: 24px;
    font-size:     12px;
    font-weight:   500;
}


//.StripeElement {
//    background:    rgba(239, 240, 247, 0.05);
//    border:        1px solid rgba(255, 255, 255, 0.5);
//    border-radius: 16px !important;
//    padding:       0 0.75rem;
//    transition:    all 200ms;
//
//    &:hover {
//        border-color: white;
//    }
//
//}

@media only screen and (max-width: 1000px) {
    #hubspot-messages-iframe-container {
        z-index: 9 !important;
    }
}
.text-blue{
    color:#1B2540
}

select.select-price-sorting.form-select{
    color:#1B2540 !important;
}

select.select-price-sorting.form-select:focus{
    box-shadow: none;
    border: none
}

.jso-booking-form-guests{
    .jso-quantity-selector-dropdown.jso-dropdown {
        background-color: #FFFFFF !important;
        color: #1B2540 !important;
    }
    .jso-quantity-selector-popup.jso-popup {
        background-color: #FFFFFF;

        .jso-quantity-selector-popup-content, .jso-popup-content-top {
            color: #1B2540 !important;
        }

        .jso-popup-content-top {
            border-bottom: 1px solid #1B2540;
            padding-bottom: 5px;
            margin-bottom: 8px;
        }

        .jso-quantity-selector-popup-footer {
            button {
                background-color: #1B2540;
                color: #FFFFFF;
            }
        }
    }
}