.reservation-cards-container {
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.account-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 70px;
    margin-bottom: 44px;

    .account-name{
        margin-bottom: 0px;
        color: #1B2540 !important;
        font-family: 'Cormorant Garamond';
        font-weight: 500;
        font-size: 80px;
        line-height: 80px;
        letter-spacing: -1.6px;
        text-align: left;
        flex: 1;

        @media only screen and (max-width: 768px) {
            font-size: 40px;
            line-height: 52px;
            letter-spacing: -0.8px;
        }
    }

    .logout {
        padding: 16px 24px;
        display: flex;
        align-items: center;
        gap: 8px;
        background-color: transparent;
        color: #1B2540;
        font-family: 'Montserrat';
        font-size: 14px;
        font-weight: 500;
        line-height: 20.3px;
        letter-spacing: 1.12px;
        text-transform: uppercase;
        cursor: pointer;

        @media only screen and (max-width: 768px) {
            padding: 8px;
        }
    }
}

.trips-tabs-container {
    display: flex;
    margin-bottom: 44px;
    
    .trip-tab {
        color: #1B2540;
        font-family: "Montserrat";
        font-size: 16px;
        line-height: 25.6px;
        font-weight: 500;
        text-transform: uppercase;
        padding: 12px 24px;
        cursor: pointer;
    }

    .active-tab {
        background-color: #FFFFFF !important;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0);
    }    
}

.reservation-card {
    background-color: #FFFFFF;
    padding: 24px !important;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    .image-info {
        display: flex;
        gap: 24px;
        align-items: center;
        width: 100%;

        .image {
            height: 120px;
            width: 189px;

            @media only screen and (max-width: 1200px) {
                height: 160px !important;
                width: 100%;
            }

            img {
                height: 100%;
                width: 189px;
                object-fit: cover;

                @media only screen and (max-width: 1200px) {
                    width: 100% !important;
                }
            }
        }

        .info {
            display: flex;
            flex-direction: column;
            gap: 16px;
            width: 100%;

            .title-name {
                display: flex;
                flex-direction: column;
                gap: 8px;
                width: 100%;

                .title {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22.4px;
                    color: #676767;
                    margin-bottom: 0;
                    position: relative;

                    @media only screen and (max-width: 1200px) {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        width: 100%;
                    }    

                    .card-more-btn.card-more-btn-mobile {
                        @media only screen and (min-width: 1200px) {
                            display: none;
                        }
                    }
                }

                .name {
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 28.8px;
                    color: #1B2540;
                    margin-bottom: 0;
                }
            }
        }

        .details {
            display: flex;
            align-items: center;
            gap: 24px;

            .details-items {
                display: flex;
                align-items: center;
                gap: 4px;

                .key {
                    color: #1B2540;
                    opacity: 50%;
                    font-family: 'Montserrat';
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22.4px;

                    svg {
                        height: 20px;
                        width: 20px;
                    }
                }

                .value {
                    color: #1B2540;
                    font-family: 'Montserrat';
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 22.4px;
                }
            }

            @media only screen and (max-width: 1200px) {
                flex-direction: column;
                align-items: flex-start;
                gap: 12px
            }
        }

        @media only screen and (max-width: 1200px) {
            flex-direction: column;
            align-items: flex-start;
        }
    }

    .buttons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 16px;
        position: relative;
        width: 25%;

        @media only screen and (max-width: 1200px) {
            width: 100%;
        }

        .details-btn {
            color: #1B2540 !important;
            padding: 20px 50px;
            background-color: rgba(27, 37, 64, 0.2);
            font-family: 'Montserrat';
            font-weight: 500;
            font-size: 12px;
            line-height: 12px;
            letter-spacing: 0.24px;
            text-align: center;
            border: 0;
            cursor: pointer;
            text-transform: uppercase;

            @media only screen and (max-width: 1200px) {
                width: 100%;
            }
        }

        .card-more-btn.card-more-btn-desktop {
            @media only screen and (max-width: 1200px) {
                display: none;
            }
        }
    }

    .card-more-btn {
        cursor: pointer;
    }
    
    .more-dropdown-menu {
        position: absolute;
        right: 0;
        top: 51px;
        background: #FFF;
        box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.12);
    
        .menu-item {
            width: 280px;
            padding: 20px;
            font-family: 'Montserrat';
            font-size: 14px;
            font-weight: 500;
            line-height: 20.3px;
            letter-spacing: 1.12px;
            text-transform: uppercase;
            text-align: center;
            color: #000000;
            cursor: pointer;
        }
    
        .menu-item.danger {
            color: #BA1A1A;
        }
    }
    .more-dropdown-menu.more-dropdown-menu-mobile {
        top: 25px;
        width: 320px;
    }

    @media only screen and (max-width: 1200px) {
        .more-dropdown-menu.more-dropdown-menu-desktop {
            display: none;
        }
    }

    @media only screen and (min-width: 1200px) {
        .more-dropdown-menu.more-dropdown-menu-mobile {
            display: none;
        }
    }

    @media only screen and (max-width: 1200px) {
        flex-direction: column;
        gap: 24px
    }
}