.jso-dropdown {
    flex-direction: column;
    &.hidden {
        display: none;
    }

    .jso-autocomplete-item {
        border-bottom: none;

        &:hover, &.active {
            background-color: #4a4e67 !important;
        }
    }
}

.dateRange{
    .rdrDay.rdrDaySelected .rdrDayNumber span {
        background-color: #4CAF50;
        color: white;
        border-radius: 50%;
      }
      
      .rdrDay.rdrDaySelected .rdrDayNumber span:hover {
        background-color: #45a049;
        color: white;
      }
}